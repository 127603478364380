<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Stocks tampons</span>
    <span v-if="totalCount > 0" class="PageTitle__count">{{ totalCount }}</span>
  </div>
  <div class="Stocks__main">
    <section class="Stocks__list LineList">
      <div class="LineList__headers">
        <div class="LineList__header">Stock</div>
        <div class="LineList__header">Dernière commande</div>
        <div class="LineList__header">Adresse</div>
        <div class="LineList__header"></div>
        <div class="LineList__header"></div>
        <div class="LineList__header"></div>
      </div>
      <Loader v-if="isLoading" />
      <div v-else class="Line" v-for="stock in stocks" :key="stock.id">
        <router-link :to="{name: 'Stock', params: {id: stock.id}}"><div class="Stock-name">{{ stock.name }}</div></router-link>
        <div class="stock-last-update">
          <template v-if="stock.last_order">
            {{ getTimeRemaining(stock.last_order.date) }} ({{
              stock.last_order.product_count
            }}
            produits)
          </template>
          <template v-else>
            N/A
          </template>
        </div>
        <div class="stock-address">{{ stock.address }}</div>
        <router-link :to="{name: 'EditStock', params: {id: stock.id}}" class="stock-edit">Modifier le stock</router-link>
        <router-link :to="{name: 'Projections', params: {id: stock.id}}" class="stock-edit">Gérer les frigos</router-link>
        <router-link :to="{name: 'StockOrders', params: {id: stock.id}}" class="stock-edit">Voir les commandes ({{ stock.in_progress_order_count }})</router-link>
      </div>
      <pagination
        v-if="totalCount > 10"
        v-model="page"
        :records="totalCount"
        :perPage="perPage"
        @paginate="getStocks"
      />
    </section>
    <div class="Stocks__planogram">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import Pagination from "v-pagination-3";
import dayjs from "dayjs";
import Loader from "../components/Loader";
import notificationService from "../services/notification";
import StockService from "../services/stock";

export default {
  data() {
    return {
      isLoading: false,
      stocks: [],
      page: 1,
      perPage: 10,
      totalCount: 0
    };
  },
  methods: {
    getStocks() {
      this.isLoading = true;

      StockService.getAll({ page: this.page })
        .then(({ data: stocks, headers }) => {
          this.stocks = stocks;
          this.perPage = parseInt(headers["page-items"]);
          this.totalCount = parseInt(headers["total-count"]);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getTimeRemaining: function(date) {
      if (!date) {
        return "";
      }

      return dayjs(date).fromNow();
    }
  },
  created() {
    this.getStocks();
  },
  components: {
    Pagination,
    Loader
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.LineList__headers,
.Line {
  grid-template-columns: 4fr 3fr 5fr 2fr 2fr 2fr;
}

.router-link-exact-active {
  background: $color-secondary;
}

.Stocks {
  &__main {
    display: flex;
  }

  &__list {
    width: 100%;
    flex-shrink: 0;
    margin-right: 50px;
  }

  &__planogram {
    width: 100%;
  }
}

.stock {
  &-name {
    font-weight: bold;
  }

  &-last-update {
    font-size: 0.875em;
  }

  &-address {
    font-size: 0.75em;
  }

  &-edit {
    color: $color-grey;
    font-size: 0.875em;
  }
}
</style>
