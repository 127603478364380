import { Factory } from "miragejs";
import faker from "faker";

export default Factory.extend({
  "images": [{
    small: "https://i.pinimg.com/564x/c3/9c/f6/c39cf663eeaf88860e176bfbc1c80d16.jpg",
    medium: "https://i.pinimg.com/564x/c3/9c/f6/c39cf663eeaf88860e176bfbc1c80d16.jpg",
    big: "https://i.pinimg.com/564x/c3/9c/f6/c39cf663eeaf88860e176bfbc1c80d16.jpg"
  }],
  "list_thumbnail": "https://i.pinimg.com/564x/c3/9c/f6/c39cf663eeaf88860e176bfbc1c80d16.jpg",
  "discover_thumbnail": "https://i.pinimg.com/564x/c3/9c/f6/c39cf663eeaf88860e176bfbc1c80d16.jpg",
  "price": 12.0,
  "ingredients": "ingredients, ingredients",
  "weight": 150,
  "nu_ref": "azearazr",
  "icon": "string",
  "allergen": "allergen",
  "nutritional_facts": {
    "calorie_intake_kj": null,
    "calorie_intake_kcal": 130,
    "fat": 6,
    "saturated_fatty_acids": 2.8,
    "carbohydrate": 13,
    "sugar": 1.8,
    "dietary_fibre": 0.8,
    "protein": 5.5,
    "salt": 0.67,
    "calorie_intake_kj_portion": 1629,
    "calorie_intake_kcal_portion": 390,
    "fat_portion": 18,
    "saturated_fatty_acids_portion": 9,
    "carbohydrate_portion": 39,
    "sugar_portion": 6,
    "dietary_fibre_portion": 3,
    "protein_portion": 18,
    "salt_portion": 3,
    "calorie_intake_percent": 19.5,
    "fat_percent": 25.7,
    "saturated_fatty_acids_percent": 45,
    "carbohydrate_percent": 15,
    "sugar_percent": 6.7,
    "protein_percent": 36,
    "salt_percent": 50
  },
  name() {
    return faker.commerce.productName();
  },
  description() {
    return faker.commerce.productDescription();
  }
});
