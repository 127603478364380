<template>
  <div v-if="fridge" class="Fridge">
    <div class="Fridge__header">
      <div class="Fridge__name">{{ fridge.name }}</div>
      <div class="PageTitle__btns">
        <el-button class="Button Button--outline" :loading="isLoadingSync" @click="syncFridge">
          Mettre à jour depuis Nu!
        </el-button>
      </div>
    </div>
    <div v-if="fridge.buffer_stock" class="Fridge__container"><router-link :to="{name: 'Tours', query: {fridge_id: fridge.id, buffer_stock_id: fridge.buffer_stock.id}}" class="Fridge__btn">Afficher les tournées de ce frigo</router-link></div>
    <div v-else></div>
    <div class="Fridge__main">
      <div class="Fridge__aside">
        <div class="Fridge__stock-container">
          <div class="Fridge__stock-infos">
            <div class="InfoGroup">
              <span class="InfoGroup__label">Stock tampon assigné</span>
              <span class="InfoGroup__value">{{ selectedStockName }}</span>
            </div>
            <div class="InfoGroup">
              <span class="InfoGroup__label">Code Regate d'entrée</span>
              <span class="InfoGroup__value">{{ fridge.entry_regate_code || "N/A" }}</span>
            </div>
            <div class="InfoGroup">
              <span class="InfoGroup__label">Code Regate de sortie</span>
              <span class="InfoGroup__value">{{ fridge.regate_code || "N/A" }}</span>
            </div>
            <div class="InfoGroup">
              <span class="InfoGroup__label">Adresse</span>
              <span class="InfoGroup__value">{{ fridge.address || "N/A" }}</span>
            </div>
            <div class="InfoGroup">
              <span class="InfoGroup__label">Contact dans l'entreprise</span>
              <template v-if="fridge.company_contact">
                <span v-for="(contact, index) in fridge.company_contact.split(/\r?\n/)" :key="index" class="InfoGroup__value">{{ contact }}</span>
              </template>
              <span v-else class="InfoGroup__value">N/A</span>
            </div>
            <div class="InfoGroup">
              <span class="InfoGroup__label">Contact Marie en cas de problème</span>
              <template v-if="fridge.marie_contact">
                <span v-for="(contact, index) in fridge.marie_contact.split(/\r?\n/)" :key="index" class="InfoGroup__value">{{ contact }}</span>
              </template>
              <span v-else class="InfoGroup__value">N/A</span>
            </div>
          </div>
          <el-icon
            class="stock-edit-btn"
            color="#7e7779"
            :width="18"
            @click="editFridge"
          >
            <el-icon-edit-pen />
          </el-icon>
        </div>
        <Button :disabled="isLoading" @click="exportOutput" name="save">Exporter les sorties</Button>

        <el-dialog width="500px" v-model="showEditDialog" destroyOnClose>
          <div class="Fridge__stock-form">
            <span class="Fridge__dialog-title">Modifier ce frigo</span>
            <div class="InputGroup">
              <label class="InputGroup__label">Stock tampon assigné</label>
              <select class="InputGroup__field" v-model="fridgeForm.buffer_stock_id">
                <option v-for="stock in stocks" :key="stock.id" :value="stock.id">{{ stock.name }}</option>
              </select>
            </div>
            <div class="InputGroup">
              <label class="InputGroup__label" for="entry-regate-code">Code Regate d'entrée</label>
              <input class="InputGroup__field" id="entry-regate-code" v-model="fridgeForm.entry_regate_code" />
            </div>
            <div class="InputGroup">
              <label class="InputGroup__label" for="regate-code">Code Regate de sortie</label>
              <input class="InputGroup__field" id="regate-code" v-model="fridgeForm.regate_code" />
            </div>
            <div class="InputGroup">
              <label class="InputGroup__label" for="address">Adresse</label>
              <textarea class="InputGroup__field" id="address" v-model="fridgeForm.address"></textarea>
            </div>
            <div class="InputGroup">
              <label class="InputGroup__label">Contact dans l'entreprise</label>
              <multi-input placeholder="Nom Prénom - 06XXXXXXXX" v-model="fridgeForm.company_contact" moreLabel="Ajouter un contact" />
            </div>
            <div class="InputGroup">
              <label class="InputGroup__label">Contact Marie en cas de problème</label>
              <multi-input placeholder="Nom Prénom - 06XXXXXXXX" v-model="fridgeForm.marie_contact" moreLabel="Ajouter un contact" />
            </div>
            <div class="actions">
              <Button :disabled="!fridgeForm.buffer_stock_id" :loading="isLoading" @click="save" name="save">Enregistrer</Button>
            </div>
          </div>
        </el-dialog>

        <div class="Fridge__projections" v-if="fridge.buffer_stock">
          <h3>Projections Futures</h3>
          <ProjectionList class="Fridge__projection" :fridge="fridge" :projections="nextProjections" />

          <h3>Projections Passées</h3>
          <ProjectionList class="Fridge__projection" :fridge="fridge" :projections="previousProjections" />
        </div>
      </div>

      <div class="Fridge__plano" :key="$route.fullPath">
        <div v-for="i in [0, 1, 2, 3]" :key="i" class="Fridge__plano-row">
          <div v-for="j in [0, 1, 2, 3, 4]" :key="j" class="Fridge__card">
            <div
              class="PlanoCard"
              v-if="planogramLookup && planogramLookup[i] && planogramLookup[i][j] && planogramLookup[i][j].product"
            >
              <div class="PlanoCard__img" :style="thumbnailStyle(planogramLookup[i][j].product)">
                <div class="PlanoCard__thumbnail">{{ planogramLookup[i][j].quantity }}</div>
              </div>

              <div class="PlanoCard__infos">
                <div class="row PlanoCard__header">
                  <div @click="editPrice(planogramLookup[i][j])" class="PlanoCard__price">{{ priceFormat(planogramLookup[i][j].price) + "€" }}
                    <el-icon>
                      <el-icon-edit-pen />
                    </el-icon>
                  </div>
                  <div class="PlanoCard__expiry" v-if="planogramLookup[i][j].expiry">{{ expiryFormat(planogramLookup[i][j].expiry) }}</div>
                </div>
                <div class="PlanoCard__name">{{ planogramLookup[i][j].product.name }}</div>
              </div>
            </div>
            <div class="PlanoCard" v-else>
              <div class="PlanoCard__img"></div>
              <div class="PlanoCard__infos"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="currentPrice"
      v-model="displayPriceDialog"
      width="270px"
      title="Modifier le prix"
      :showClose="false"
      @close="currentPrice = null"
      class="PriceDialog"
    >
      <el-form @submit.prevent="savePrice" labelPosition="top">
        <el-form-item>
          <el-input :readonly="isSavingPrice" v-model="currentPrice.price" :inputStyle="{textAlign: 'right'}" />
        </el-form-item>

        <div class="align-right">
          <el-button :disabled="isSavingPrice" @click="displayPriceDialog = false">Cancel</el-button>
          <el-button :loading="isSavingPrice" type="primary" @click="savePrice">OK</el-button>
        </div>
      </el-form>

    </el-dialog>

  </div>
  <Loader v-else />
</template>

<script>
import dayjs from "dayjs";
import notificationService from "../services/notification";
import priceFormat from "../utils/priceFormat";
import expiryFormat from "../utils/expiryFormat";
import FridgeService from "../services/fridge";
import Button from "../components/Button";
import Loader from "../components/Loader";
import MultiInput from "../components/MultiInput";
import ProjectionList from "../components/ProjectionList";
import StockService from "../services/stock";

export default {
  data() {
    return {
      fridge: null,
      isLoading: false,
      isLoadingSync: false,
      stocks: [],
      displayPriceDialog: false,
      currentPrice: null,
      isSavingPrice: false,
      showEditDialog: false,
      fridgeForm: null
    };
  },
  computed: {
    planogramLookup() {
      if (!this.fridge?.planogram) {
        return null;
      }

      return this.fridge?.planogram.slots.reduce((acc, slot) => {
        if (!acc[slot.shelf_index]) {
          acc[slot.shelf_index] = {};
        }

        acc[slot.shelf_index][slot.slot_index] = slot;

        return acc;
      }, {}
      );
    },

    nextProjections() {
      return this.fridge.projections.filter(p => dayjs().isBefore(dayjs(p.date)));
    },
    previousProjections() {
      return this.fridge.projections.filter(p => dayjs().isAfter(dayjs(p.date)));
    },
    selectedStockName() {
      if (this.stocks.length === 0) {
        return "Aucun stock disponible";
      }

      if (!this.fridge.buffer_stock) {
        return "Aucun stock sélectionné";
      }

      return this.stocks.find(stock => stock.id === this.fridge.buffer_stock.id).name;
    }
  },
  methods: {
    thumbnailStyle(product) {
      if (product && product.discover_thumbnail && product.discover_thumbnail.medium) {
        return {
          backgroundImage: `url(${process.env.VUE_APP_API_URL + product.discover_thumbnail.medium})`
        };
      }
    },
    priceFormat,
    getStocks() {
      this.isLoading = true;

      StockService.getAll()
        .then(({ data: stocks }) => this.stocks = stocks)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getFridge() {
      this.isLoading = true;

      FridgeService.get(this.$route.params.id)
        .then(({ data: fridge }) => this.fridge = fridge)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    save() {
      this.isLoading = true;

      FridgeService.put(`${this.fridge.id}`, {
        ...this.fridgeForm,
        company_contact: this.fridgeForm.company_contact?.join("\n") || null,
        marie_contact: this.fridgeForm.marie_contact?.join("\n") || null
      }, { "Content-Type": "application/json" })
        .then(({ data: fridge }) => {
          this.fridge = fridge;
          notificationService.success("Le frigo a été mis à jour");
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);

      this.showEditDialog = false;
    },
    syncFridge() {
      this.isLoadingSync = true;

      FridgeService.syncFridge(this.fridge.id)
        .then(() => this.getFridge(this.fridge.id))
        .then(() => notificationService.success("Le frigo a été mis à jour"))
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoadingSync = false);
    },
    exportOutput() {
      FridgeService.exportOutput(this.fridge.id)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");

          const filename =
            response.headers["content-disposition"]?.split("filename=")[1]?.split(";")[0]
            ?? "sorties_output.csv";

          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => notificationService.error(err.message, err.errors));
    },
    editPrice(p) {
      this.currentPrice = {
        price: parseFloat(p.price).toFixed(2),
        id: p.id
      };

      this.displayPriceDialog = true;
    },
    savePrice() {
      if (!this.currentPrice?.price) {
        return;
      }

      this.isSavingPrice = true;

      FridgeService.updatePrice(this.currentPrice.id, { price: parseFloat(this.currentPrice.price) })
        .then(() => {
          this.getFridge();
          this.displayPriceDialog = false;
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isSavingPrice = false);
    },
    editFridge() {
      this.fridgeForm = {
        regate_code: this.fridge.regate_code || "",
        entry_regate_code: this.fridge.entry_regate_code || "",
        address: this.fridge.address || "",
        buffer_stock_id: this.fridge.buffer_stock?.id || null,
        company_contact: this.fridge.company_contact?.split(/\r?\n/) || [],
        marie_contact: this.fridge.marie_contact?.split(/\r?\n/) || []
      };

      this.showEditDialog = true;
    },
    expiryFormat
  },
  created() {
    this.getStocks();
    this.getFridge();
  },
  components: { Button, ProjectionList, Loader, MultiInput }
};
</script>

<style lang="scss" scoped>
.Fridge {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $global-margin;
  }

  &__container {
    margin-top: $global-margin * 2;
    margin-bottom: $global-margin;
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    color: $color-grey;
    text-decoration: underline;
  }

  &__name {
    font-weight: bold;
    font-size: 1.5rem;
  }

  &__main {
    display: flex;
    justify-content: space-between;
  }

  &__aside {
    width: 37%;
  }

  &__projections {
    margin-top: 40px;
  }

  &__projection {
    margin-bottom: 40px;
  }

  &__plano {
    padding: $global-margin;
    background: #dee8d6;
    width: 60%;
  }

  &__plano-row {
    border-bottom: 5px solid #d6af81;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 0.5rem;
    background: #fff;
  }

  &__card {
    background: #fff;
    border-radius: $global-radius;
    overflow: hidden;
    box-shadow: 1px 1px 10px #ccc;
  }

  &__stock-container {
    display: flex;
    justify-content: space-between;
    padding: $global-margin $global-margin 0 $global-margin;
    margin-bottom: $global-margin;
    background-color: $color-secondary;
    box-sizing: content-box;
  }

  &__stock-infos {
    display: flex;
    flex-direction: column;
  }

  .InfoGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: $global-margin;

    &__label {
      font-weight: bold;
      margin-bottom: $global-margin * 0.5;
      font-size: 14px;
    }

    &__value {
      font-size: 16px;
      white-space: pre;
    }
  }

  .stock-edit-btn {
    cursor: pointer;
  }

  &__dialog-title {
    font-weight: bold;
    font-size: 21px;
  }
}

.PlanoCard {
  background: #fff;

  &__img {
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    background-size: cover;
    background-position: center;
  }

  &__infos {
    padding: 0.5rem;
    height: 110px;
  }

  &__thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background: $color-black;
    color: #fff;
    float: right;
    margin: $global-margin / 2;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 11px;
  }

  &__header {
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &__price {
    font-weight: bold;
    font-size: 0.9rem;
    cursor: pointer;
  }

  &__expiry {
    color: $color-grey;
    font-size: 0.9rem;
  }

  &__name {
    font-size: 0.8rem;
    line-height: 1.2;
  }
}

.Fridge__stock-form {
  color: $color-black;

  .InputGroup {
    margin-top: $global-margin;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__label {
      margin-bottom: $global-margin * 0.5;
    }

    &__field {
      font-weight: 400;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: $global-margin;
  }
}

</style>
