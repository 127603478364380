import axios from "axios";
import userService from "../services/user";

export default {
  namespaced: true,
  state: {
    status: "",
    token: "",
    user: {}
  },
  mutations: {
    authRequest(state) {
      state.status = "loading";
    },
    authSuccess(state, { token, user }) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    authError(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    }
  },
  actions: {
    setUser({ commit }, data) {
      const token = data.authentication_token;

      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Token token=${token}`;
      commit("authSuccess", { token, user: data.user });
    },
    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit("authRequest");

        userService.login(user)
          .then(resp => {
            dispatch("setUser", resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit("authError");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    getUser({ commit }) {
      return userService.getUser().then(({ data }) => {
        const token = localStorage.getItem("token");

        axios.defaults.headers.common["Authorization"] = `Token token=${token}`;
        commit("authSuccess", { token, user: data.user });
      });
    },
    confirmPassword({ commit, dispatch }, { password, passwordConfirmation, token, cgu, majority }) {
      return new Promise((resolve, reject) => {
        userService.resetPassword({
          password,
          "password_confirmation": passwordConfirmation,
          "reset_password_token": token,
          ...cgu && { "accept_cgu": cgu }
        }).then(resp => {
          if (resp.data.user.role === "admin") {
            dispatch("setUser", resp.data);
          }

          resolve(resp);
        }).catch(err => {
          reject(err);
        });
      });
    }
  },
  getters: {
    isLoggedIn: state => !!state.token
  }
};
