import { Factory } from "miragejs";
import faker from "faker";

export default Factory.extend({
  name() {
    return faker.commerce.productName();
  },
  nu_id() {
    return faker.random.uuid();
  },

  enabled: true
});
