<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Faq</span>
    <div class="PageTitle__btns">
      <el-button size="large" type="primary" @click.prevent="addSection">Nouvelle section</el-button>
      <el-button size="large" type="primary" @click.prevent="addQuestion">Nouvelle question</el-button>
    </div>
  </div>
  <div class="Faq">
    <draggable
      :list="sections"
      tag="el-collapse"
      itemKey="id"
      @change="updateSectionsPostion"
      :disabled="isLoading"
    >
      <template #item="{element}">
        <el-collapse-item :name="element.id">
          <template #title>
            <div class="Faq__section-title">{{ element.name }}
              <i @click.stop="editSection(element)" class="el-icon-edit-outline"></i>
              <el-icon @click.stop="editSection(element)">
                <el-icon-edit />
              </el-icon>
            </div>
          </template>
          <FaqQuestions
            @edit="editQuestion"
            :questions="element.questions"
          />
        </el-collapse-item>
      </template>
    </draggable>
    <el-dialog
      :title="`${currentSection.id ? 'Modifier' : 'Créer'} une section`"
      width="450px"
      v-if="currentSection"
      v-model="displaySectionDialog"
      destroyOnClose
      @closed="currentSection = null"
    >
      <el-form labelPosition="top">
        <el-form-item label="Intitulé">
          <el-input v-model="currentSection.name" />
        </el-form-item>
        <div class="align-right">
          <el-button v-if="currentSection.id"
            :disabled="isLoading"
            :loading="isRemoving"
            @click="removeSection"
            plain
            type="primary"
          >Supprimer
          </el-button>
          <el-button :disabled="isLoading" @click="displaySectionDialog = false" type="info">Annuler</el-button>
          <el-button :disabled="isRemoving" :loading="isLoading" @click="saveSection" type="primary">Enregistrer</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="`${currentQuestion.id ? 'Modifier' : 'Créer'} une question`"
      width="600px"
      v-model="displayQuestionDialog"
      v-if="currentQuestion"
      destroyOnClose
      @closed="currentQuestion = null"
    >
      <el-form labelPosition="top">
        <el-form-item label="Intitulé">
          <el-input v-model="currentQuestion.title" />
        </el-form-item>
        <el-form-item label="Section">
          <el-select class="Faq__section-select" v-model="currentQuestion.section_id">
            <el-option
              v-for="section in sections"
              :key="section.id"
              :label="section.name"
              :value="section.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Réponse">
          <el-input rows="10" type="textarea" v-model="currentQuestion.answer" />
        </el-form-item>
        <div class="align-right">
          <el-button
            :disabled="isLoading"
            :loading="isRemoving"
            v-if="currentQuestion.id"
            @click="removeQuestion"
            plain
            type="primary"
          >Supprimer</el-button>
          <el-button :disabled="isLoading" @click="displayQuestionDialog = false" type="info">Annuler</el-button>
          <el-button :disabled="isRemoving" :loading="isLoading" @click="saveQuestion" type="primary">Enregistrer</el-button>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import { ElMessageBox } from "element-plus";

import notificationService from "../services/notification";
import FaqService from "../services/faq";
import FaqQuestions from "../components/FaqQuestions";

export default {
  data() {
    return {
      isLoading: false,
      isRemoving: false,
      sections: [],
      currentSection: null,
      displaySectionDialog: false,
      currentQuestion: null,
      displayQuestionDialog: false
    };
  },
  methods: {

    addSection() {
      this.currentSection = {
        name: ""
      };

      this.displaySectionDialog = true;
    },
    editSection(s) {
      this.currentSection = (({ position, ...o }) => o)(s);
      this.displaySectionDialog = true;
    },
    addQuestion() {
      this.currentQuestion = {
        title: "",
        anwser: "",
        section_id: null
      };

      this.displayQuestionDialog = true;
    },
    editQuestion(q) {
      this.currentQuestion = (({ position, ...o }) => o)(q);
      this.displayQuestionDialog = true;
    },
    saveSection() {
      let request;

      this.isLoading = true;

      if (this.currentSection.id) {
        request = FaqService.updateSection(this.currentSection.id, this.currentSection);
      } else {
        request = FaqService.createSection(this.currentSection);
      }

      request.then(() => {
        this.displaySectionDialog = false;
        this.currentSection = null;
        this.getFaq();
      })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    saveQuestion() {
      let request;

      this.isLoading = true;

      if (this.currentQuestion.id) {
        request = FaqService.updateQuestion(this.currentQuestion.id, this.currentQuestion);
      } else {
        request = FaqService.createQuestion(this.currentQuestion);
      }

      request.then(() => {
        this.displayQuestionDialog = false;
        this.currentQuestion = null;
        this.getFaq();
      })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    removeSection() {
      ElMessageBox.confirm(
        "Etes-vous sûr de vouloir supprimer cette section ?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Annuler",
          type: "warning"
        }
      ).then(() => {
        this.isRemoving = true;

        FaqService.deleteSection(this.currentSection.id)
          .then(() => {
            this.sections = this.sections.filter(c => c.id !== this.currentSection.id);
            this.displaySectionDialog = false;
          })
          .catch(err => notificationService.error(err.message, err.errors))
          .finally(() => this.isRemoving = false);
      });
    },
    removeQuestion() {
      ElMessageBox.confirm(
        "Etes-vous sûr de vouloir supprimer cette question ?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Annuler",
          type: "warning"
        }
      ).then(() => {
        this.isRemoving = true;

        FaqService.deleteQuestion(this.currentQuestion.id)
          .then(() => {
            this.displayQuestionDialog = false;
            this.currentQuestion = null;
            this.getFaq();
          })
          .catch(err => notificationService.error(err.message, err.errors))
          .finally(() => this.isRemoving = false);
      });
    },
    updateSectionsPostion(evt) {
      this.isLoading = true;

      FaqService.orderSections(this.sections.map(s => s.id))
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getFaq() {
      FaqService.get()
        .then(({ data }) => this.sections = data)
        .catch(err => notificationService.error(err.message, err.errors));
    }
  },
  created() {
    this.getFaq();
  },
  components: {
    draggable,
    FaqQuestions
  }
};
</script>

<style lang="scss" scoped>
.Faq {
  &__section-title {
    font-size: 1.2rem;
    font-weight: bold;
    cursor: grab;
    i {
      font-size: 0.9rem;
      cursor: pointer;
      margin-left: 5px;
    }
  }
  &__section-select {
    width: 100%;
  }
}

</style>
