<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Catégories</span>
    <span v-if="totalCount > 0" class="PageTitle__count">{{ totalCount }}</span>
    <div class="PageTitle__btns">
      <el-button size="large" type="primary" @click.prevent="displayNewForm">Nouvelle catégorie</el-button>
    </div>
  </div>
  <div class="Categories__main">
    <section class="Categories__list LineList">
      <div class="LineList__headers">
        <div class="LineList__header">Nbr de produits</div>
        <div class="LineList__header">Nom</div>
      </div>
      <Loader v-if="isLoading" />
      <a
        @click="displayEditForm(c)"
        :class="{ 'is-selected' : current && current.id === c.id}"
        v-else
        class="Line"
        v-for="c in categories"
        :key="c.id"
      >
        <div class="category-id">{{ c.product_count }}</div>
        <div class="name">{{ c.name }}</div>
      </a>
    </section>
    <el-form labelPosition="left" @submit.prevent="save" class="Form" v-if="current">
      <div class="Form__title">{{ current.id ? "Mettre à jour la  catégorie" : "Créer une nouvelle catégorie" }}</div>
      <el-form-item label="Nom">
        <el-input v-model="current.name" />
      </el-form-item>
      <div class="align-right" v-if="current.id">
        <el-button size="small" type="info" @click="remove">Supprimer cette catégorie</el-button>
        <el-button size="small" :disabled="current.name === ''" type="primary" @click="save">Enregistrer</el-button>
      </div>
      <div class="align-right" v-else>
        <el-button size="small" type="info" @click="current.name = ''">Annuler</el-button>
        <el-button size="small" :disabled="current.name === ''" type="primary" @click="save">Ajouter</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import notificationService from "../services/notification";
import CategoryService from "../services/category";

export default {
  data() {
    return {
      isLoading: false,
      categories: [],
      current: null
    };
  },
  methods: {
    getCategories() {
      this.isLoading = true;

      CategoryService.getAll()
        .then(({ data: categories }) => this.categories = categories)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    displayNewForm() {
      this.current = {
        name: ""
      };
    },
    displayEditForm(c) {
      this.current = { ...c };
    },
    save() {
      let request;

      this.isLoading = true;

      const formData = new FormData();

      formData.append("name", this.current.name);

      if (this.current.id) {
        request = CategoryService.put(this.current.id, formData);
      } else {
        request = CategoryService.post(formData);
      }

      request.then(() => {
        this.getCategories();
        this.displayNewForm();
      })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    remove() {
      if (this.current.product_count === 0) {
        CategoryService.delete(this.current.id).then(() => {
          this.getCategories();
          this.current = null;
        })
          .catch(err => notificationService.error(err.message, err.errors))
          .finally(() => this.isLoading = false);
      } else {
        notificationService.error("Impossible de supprimer cette catégorie car elle contient encore des produits");
      }
    }
  },
  created() {
    this.getCategories();
  },
  components: {
    Loader
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.LineList__headers,
.Line {
  grid-template-columns: 1fr 5fr;
}

.is-selected {
  background: $color-secondary;
}

.Categories {
  &__main {
    display: flex;
  }

  &__list {
    flex-shrink: 0;
    width: 50%;
    margin-right: 50px;
  }
}

.Line:hover {
  cursor: pointer;
}

</style>
