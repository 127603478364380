<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Ajout/ Edition de produit</span>
  </div>
  <div class="subtitle">Vous pouvez créer un produit indépendamment du catalogue NU! Il ne sera disponible qu’une fois lié avec cette référence</div>
  <div class="Product">
    <div class="Product__col">
      <div class="InputGroup">
        <label class="InputGroup__label">Illustration <br />(et miniature)</label>
        <ImageUpload
          :image="product.thumbnail ? product.thumbnail : null"
          @update="newfile => thumbnailFile = newfile"
        />
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Packaging</label>
        <ImageUpload
          :image="product.image ? product.image : null"
          @update="newfile => ImageFile = newfile"
        />
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Nom</label>
        <textarea class="InputGroup__field name" v-model="product.name" name="name"></textarea>
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Catégorie</label>
        <select class="InputGroup__field" v-model="product.category_id">
          <option value=""></option>
          <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
        </select>
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Description</label>
        <textarea class="InputGroup__field description" v-model="product.description" name="description"></textarea>
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Ingrédients</label>
        <textarea class="InputGroup__field ingredients" v-model="product.ingredients" name="ingredients"></textarea>
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Allergènes</label>
        <textarea class="InputGroup__field allergens" v-model="product.allergens" name="allergens"></textarea>
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Poids (grammes)</label>
        <Input class="weight" v-model="product.weight" name="weight" />
      </div>
      <div class="Product__inputs-row">
        <div class="InputGroup">
          <label class="InputGroup__label">Quantité par lot (PCB)</label>
          <Input class="weight" v-model="product.pcb" name="pcb" />
        </div>
        <div class="InputGroup">
          <label class="InputGroup__label">Quantité par Slot frigo</label>
          <Input class="weight" v-model="product.quantity_by_slot" name="quantity_by_slot" />
        </div>
      </div>
    </div>
    <div class="Product__col">
      <div class="InputGroup">
        <label class="InputGroup__label">Référence Nu!</label>
        <Input v-model="product.nu_ref" name="nu_ref" />
      </div>
      <template v-if="nuPrice">
        <div class="InputGroup">
          <label class="InputGroup__label">Prix</label>
          <Input :readonly="true" v-model="nuPrice" name="nuPrice" />
        </div>
        <div class="InputGroup">
          <label class="InputGroup__label">Label</label>
          <textarea readonly="true" class="InputGroup__field nu-label" v-model="nuLabel"></textarea>
        </div>
      </template>
      <div v-else-if="nuError" class="InputGroup">
        <label class="InputGroup__label"></label>
        <div class="error">{{ nuError }}</div>
      </div>
      <div v-else class="InputGroup">
        <label class="InputGroup__label"></label>
        <div class="error">Assurez-vous d’avoir lié un produit du catalogue Nu! pour finaliser ce produit</div>
      </div>
      <Button v-if="nuLabel" :disabled="isLoading" class="Button--secondary" @click="refresh" name="refresh">Rafraichir</Button>
      <!-- NutritionalFacts -->
      <div class="Nutritional__title">Valeurs nutritionelles <span class="Nutritional__unit">(g)</span></div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label"></label>
        <div class="Nutritional__headers">Pour 100g</div>
        <div class="Nutritional__headers">Par Portion</div>
        <div class="Nutritional__headers">% des AQR* par portion</div>
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Calories <span class="Nutritional__unit">(Kj)</span></label>
        <Input v-model="product.nutritional_facts.calorie_intake_kj" name="calorie_intake_kj" />
        <Input v-model="product.nutritional_facts.calorie_intake_kj_portion" name="calorie_intake_kj_portion" />
        <Input v-model="product.nutritional_facts.calorie_intake_percent" name="calorie_intake_percent" />
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Calories <span class="Nutritional__unit">(Kcal)</span></label>
        <Input v-model="product.nutritional_facts.calorie_intake_kcal" name="calorie_intake_kcal" />
        <Input v-model="product.nutritional_facts.calorie_intake_kcal_portion" name="calorie_intake_kcal_portion" />
        <div></div>
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Matières grasses</label>
        <Input v-model="product.nutritional_facts.fat" name="fat" />
        <Input v-model="product.nutritional_facts.fat_portion" name="fat_portion" />
        <Input v-model="product.nutritional_facts.fat_percent" name="fat_percent" />
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Acides Gras</label>
        <Input v-model="product.nutritional_facts.saturated_fatty_acids" name="saturated_fatty_acids" />
        <Input v-model="product.nutritional_facts.saturated_fatty_acids_portion" name="saturated_fatty_acids_portion" />
        <Input v-model="product.nutritional_facts.saturated_fatty_acids_percent" name="saturated_fatty_acids_percent" />
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Glucides</label>
        <Input v-model="product.nutritional_facts.carbohydrate" name="carbohydrate" />
        <Input v-model="product.nutritional_facts.carbohydrate_portion" name="carbohydrate_portion" />
        <Input v-model="product.nutritional_facts.carbohydrate_percent" name="carbohydrate_percent" />
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Sucres</label>
        <Input v-model="product.nutritional_facts.sugar" name="sugar" />
        <Input v-model="product.nutritional_facts.sugar_portion" name="sugar_portion" />
        <Input v-model="product.nutritional_facts.sugar_percent" name="sugar_percent" />
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Fibres</label>
        <Input v-model="product.nutritional_facts.dietary_fibre" name="dietary_fibre" />
        <Input v-model="product.nutritional_facts.dietary_fibre_portion" name="dietary_fibre_portion" />
        <Input v-model="product.nutritional_facts.dietary_fibre_percent" name="dietary_fibre_percent" />
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Proteines</label>
        <Input v-model="product.nutritional_facts.protein" name="protein" />
        <Input v-model="product.nutritional_facts.protein_portion" name="protein_portion" />
        <Input v-model="product.nutritional_facts.protein_percent" name="protein_percent" />
      </div>
      <div class="Nutritional__grid">
        <label class="InputGroup__label">Sel</label>
        <Input v-model="product.nutritional_facts.salt" name="salt" />
        <Input v-model="product.nutritional_facts.salt_portion" name="salt_portion" />
        <Input v-model="product.nutritional_facts.salt_percent" name="salt_percent" />
      </div>
    </div>
  </div>
  <Button :disabled="isLoading" @click="save" name="save">Enregistrer</Button>
</template>

<script>
import productService from "../services/product";
import categoryService from "../services/category";
import ImageUpload from "../components/imageUpload";
import Input from "../components/Input";
import Button from "../components/Button";
import notificationService from "../services/notification";

const initialProduct = {
  nu_ref: "",
  name: "",
  category_id: null,
  description: "",
  images: [],
  thumbnail: null,
  ingredients: "",
  weight: null,
  allergens: "",
  pcb: "",
  quantity_by_slot: "",
  nutritional_facts: {}
};

export default {
  emits: ["beforeEnter"],
  props: {
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      product: { ...initialProduct },
      categories: [],
      isLoading: false,
      ImageFile: null,
      thumbnailFile: null,
      nuLabel: "",
      nuPrice: "",
      nuError: ""
    };
  },
  methods: {
    save() {
      this.isLoading = true;
      this.nuError = "";

      const data = Object.keys(initialProduct).reduce((formData, key) => {
        formData.append(key, this.product[key]);

        return formData;
      }, new FormData());

      Object.keys(this.product.nutritional_facts).reduce((formData, key) => {
        if (this.product.nutritional_facts[key] !== null) {
          formData.append(key, this.product.nutritional_facts[key]);
        }

        return formData;
      }, data);

      data.delete("nutritional_facts");

      if (this.ImageFile) {
        data.set("image", this.ImageFile);
      } else {
        data.delete("image");
      }

      if (this.thumbnailFile) {
        data.set("thumbnail", this.thumbnailFile);
      } else {
        data.delete("thumbnail");
      }

      let request;

      if (this.editMode) {
        request = productService.put(this.$route.params.id, data);
      } else {
        request = productService.post(data);
      }

      request
        .then(() => this.$router.push({ name: "Products" }))
        .catch(err => {
          if (err.status === 503) {
            this.nuError = err.message;
          } else {
            notificationService.error(err.message, err.errors);
          }
        })
        .finally(() => this.isLoading = false);
    },

    refresh() {
      this.isLoading = true;

      productService.getNuInfos(this.$route.params.id)
        .then(({ data: { price, nu_label } }) => {
          this.nuPrice = price;
          this.nuLabel = nu_label;
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    }
  },
  created() {
    categoryService.getAll()
      .then(({ data: categories }) => this.categories = categories)
      .catch(err => notificationService.error(err.message, err.errors));

    if (this.editMode) {
      this.isLoading = true;

      productService.get(this.$route.params.id)
        .then(({ data: product }) => {
          this.product = { ...product, category_id: product.category ? product.category.id : null };
          this.nuPrice = product.price;
          this.nuLabel = product.nu_label;
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    }
  },
  components: {
    Input,
    Button,
    ImageUpload
  }
};
</script>

<style lang="scss" scoped>
.Product {
  display: flex;

  &__col {
    width: 50%;
    padding: $global-margin * 2;
    flex-grow: 0;
    border-bottom: 1px solid $color-smoke-border;
    margin-bottom: $global-margin * 2;
  }

  &__inputs-row {
    display: flex;
    margin-bottom: $global-margin * 2;
  }
}

.InputGroup {
  flex-direction: row;

  &__label {
    width: 180px;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: $global-margin * 0.5;
    text-align: right;
    padding-right: $global-margin;
  }
}

.Button {
  margin-left: auto;
}

.subtitle {
  color: $color-grey;
  margin-bottom: $global-margin * 2;
  font-style: italic;
}

.name,
.allergens,
.nu-label {
  height: 70px;
}

.description,
.ingredients {
  height: 120px;
}

.weight,
.calories {
  text-align: right;
}

.error {
  font-style: italic;
}

.Nutritional {
  &__title {
    font-weight: bold;
    margin-bottom: $global-margin * 2;
    margin-top: $global-margin * 8;
  }

  &__unit {
    font-style: italic;
    font-size: 0.7rem;
  }

  &__headers {
    font-weight: bold;
    font-size: 0.7rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-bottom: $global-margin;

    input {
      text-align: right;
    }
  }
}
</style>
