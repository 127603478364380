import axios from "axios";
import Api from "./api";

class FaqService extends Api {
  get() {
    return axios({ url: `${this.apiVersion}/faq`, method: "GET" });
  }
  createSection(data) {
    return axios({ url: `${this.apiVersion}/admin/sections`, data, method: "POST" });
  }
  updateSection(id, data) {
    return axios({ url: `${this.apiVersion}/admin/sections/${id}`, data, method: "PUT" });
  }
  deleteSection(id) {
    return axios({ url: `${this.apiVersion}/admin/sections/${id}`, method: "DELETE" });
  }
  createQuestion(data) {
    return axios({ url: `${this.apiVersion}/admin/questions`, data, method: "POST" });
  }
  updateQuestion(id, data) {
    return axios({ url: `${this.apiVersion}/admin/questions/${id}`, data, method: "PUT" });
  }
  deleteQuestion(id) {
    return axios({ url: `${this.apiVersion}/admin/questions/${id}`, method: "DELETE" });
  }
  orderSections(data) {
    return axios({ url: `${this.apiVersion}/admin/sections/positions`, data, method: "PUT" });
  }
  orderQuestions(id, data) {
    return axios({ url: `${this.apiVersion}/admin/sections/${id}/positions`, data, method: "PUT" });
  }
}

export default new FaqService({
  ressource: "/admin/faq"
});
