<template>
  <draggable
    :list="questions"
    itemKey="id"
    @change="updateQuestionPostion"
    :disabled="isLoading"
  >
    <template #item="{element}">
      <div @click="$emit('edit', element)" class="Faq__question">{{ element.title }}
        <el-icon>
          <el-icon-edit />
        </el-icon>
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import notificationService from "../services/notification";
import FaqService from "../services/faq";

export default {
  emits: ["edit"],
  props: {
    questions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    sectionId() {
      return this.questions[0]?.section_id;
    }
  },
  methods: {
    updateQuestionPostion(evt) {
      this.isLoading = true;

      FaqService.orderQuestions(this.sectionId, this.questions.map(s => s.id))
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    }
  },
  components: {
    draggable
  }

};
</script>

<style lang="scss" scoped>
.Faq {
  &__question {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 0.8rem;
      margin-left: 5px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
