<template>
  <div>
    <div
      class="InputGroup__field"
      @click="selectFile"
    >
      {{ fileName ? fileName : "Selectioner un fichier (.pdf)" }}
    </div>
    <a class="preview" v-if="file" target="_blank" :href="previewFile">
      &#10095; Apercu du fichier
    </a>

    <input
      ref="fileInput"
      type="file"
      @input="pickFile"
    />
  </div>
</template>

<script>

export default {
  emits: ["update"],
  props: {
    file: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      fileName: null
    };
  },
  watch: {
    file(value) {
      this.previewFile = process.env.VUE_APP_API_URL + value;
    }
  },
  methods: {
    selectFile() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      const input = this.$refs.fileInput;
      const file = input.files;

      if (file && file[0]) {
        this.fileName = file[0].name;
        this.$emit("update", file[0]);
      }
    }
  }
};
</script>

<style scoped lang="scss">
input[type="file"] {
  display: none;
}

.preview {
  font-style: italic;
  font-size: 0.8rem;
  display: inline-block;
  margin-top: $global-margin * 0.5;
}
</style>
