<template>
  <button class="Button" :type="type" :disabled="loading || disabled">
    <span v-if="loading" class="Button__Loader"></span>
    <span class="Button__Content">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "button"
    }
  }
};
</script>
