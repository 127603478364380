<template>
  <section class="Section PasswordConfirmation">
    <img
      class="Brand__illustration"
      src="../assets/images/logo.png"
      alt="Marie logo"
    /><br /><br />
    <div v-if="displaySuccess">
      Votre mot de passe a été créé avec succès.<br /><br />
      Rendez-vous dès à présent sur votre application <b>Le frigo Marie</b> pour vous connecter et profiter de nos produits.
    </div>
    <form v-else @submit.prevent="reset" autocomplete="off">
      <div class="PasswordConfirmation__text" v-if="isConfirmation">
        Pour finaliser votre inscription nous avons besoin que vous choisissiez un mot de passe.
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Création d’un mot de passe</label>
        <PasswordInput :required="true" v-model="password" />
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Validation du mot de passe (vérification)</label>
        <PasswordInput :required="true" v-model="passwordConfirmation" />
      </div>
      <Button type="submit" :loading="loading" name="confirmation">Confirmation</Button>
    </form>
  </section>
</template>

<script>
import notificationService from "../services/notification";
import PasswordInput from "../components/PasswordInput";
import Button from "../components/Button";

export default {
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      loading: false,
      displaySuccess: false
    };
  },
  computed: {
    isConfirmation() {
      return typeof this.$route.query.client_type !== "undefined";
    },

    isPasswordsValid() {
      return this.password !== ""
        && this.passwordConfirmation !== ""
        && this.passwordConfirmation === this.password;
    }

  },
  methods: {
    reset: function() {
      if (!this.isPasswordsValid) {
        notificationService.error("Vous n'avez pas saisi les mêmes mots de passe.");

        return;
      }

      this.loading = true;

      this.$store.dispatch("auth/confirmPassword", {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        token: this.$route.params.token
      })
        .then(({ data }) => {
          if (data.user.role === "admin") {
            this.$router.replace("/");
            notificationService.success("Votre modification a été prise en compte.");
          } else {
            this.displaySuccess = true;
          }
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .then(() => this.loading = false);
    }
  },
  components: {
    PasswordInput,
    Button
  }
};
</script>

<style lang="scss" scoped>
  .PasswordConfirmation {
    width: 500px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #777;
    &__text {
      line-height: 1.5;
      margin-bottom: $global-margin * 2;
    }

    &__cgu {
      color: $color-primary;
      text-decoration: underline;
    }
  }
</style>
