<template>
  <div class="PageTitle" v-if="!isLoading">
    <span class="PageTitle__name">{{ stock.name }}</span>
    <div class="PageTitle__btns">
      <router-link
        :to="{ name: 'EditStock', params: { id: stock.id } }"
        class="Button Button--outline PageTitle__btn"
      >Modifier l'état du stock</router-link>

      <router-link
        :to="{ name: 'StockTourSelect', params: { id: stock.id } }"
        class="Button PageTitle__btn"
      >Nouvelle commande</router-link>
    </div>
  </div>

  <div class="StockLayout">
    <div class="StockLayout__searchContainer">
      <el-input
        clearable
        v-model="search"
        placeholder="Rechercher un produit"
        suffixIcon="el-icon-search"
        class="StockLayout__search"
      />
    </div>
    <el-tabs type="card" v-model="selectedCategory">
      <el-tab-pane
        :label="category.name"
        v-for="category in allCategories"
        :key="category.id"
        :name="category.name"
      >
        <el-table :data="filteredProducts" maxHeight="70vh" style="width: 100%;" prop="id">
          <el-table-column label="Éditer" width="100">
            <template #default="table">
              <router-link :to="{name: 'editProduct', params: { id: table.row.product_id }}" tag="span">
                <el-button link size="small">
                  <el-icon>
                    <el-icon-edit-pen />
                  </el-icon>
                </el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="Nom du produit" prop="name" width="auto" />
          <el-table-column
            align="center"
            label="Seuil minimum"
            prop="preventive_quantity"
            width="120"
            className="header-icon"
          />
          <el-table-column
            align="center"
            label="Qté réelle"
            prop="quantity"
            width="120"
            className="header-icon incomming-icon"
          />
          <el-table-column
            align="center"
            label="Qté déjà commandée"
            prop="ordered_quantity"
            width="120"
            className="header-icon in-stock-icon"
          />
          <el-table-column
            align="center"
            label="Qté à commander en PCB"
            prop="next_order_quantity"
            width="120"
            className="header-icon outgoing-icon"
          />
          <el-table-column type="expand">
            <template #default="">
              <table style="width: 100%; border-collapse: collapse;">
                <tr>
                  <td class="align-right">DLC au {{ dlc }}</td>
                  <td width="120"></td>
                  <td width="120"></td>
                  <td class="align-center" width="120">
                    {{ next_order_dlc }}
                  </td>
                  <td class="align-center" width="120">
                    {{ quantity_dlc }}
                  </td>
                </tr>
              </table>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import StockService from "../services/stock";
import CategoryService from "../services/category";
import notificationService from "../services/notification";

export default {
  data() {
    return {
      stock: null,
      categories: [],
      selectedCategory: null,
      dlc: "28/05",
      next_order_dlc: "4",
      quantity_dlc: "4",
      isLoading: false,
      search: ""
    };
  },
  computed: {
    filteredProducts() {
      if (!this.stock) {
        return [];
      }

      if (this.selectedCategory === "Sans catégories") {
        return this.stock.products.filter(p => p.category === null && p.name.toLowerCase().includes(this.search.toLowerCase()));
      }

      return this.stock.products.filter(
        p => p.category === this.selectedCategory && p.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    allCategories() {
      return [...this.categories, { id: null, name: "Sans catégories" }];
    }
  },
  methods: {
    getStock() {
      return StockService.get(this.$route.params.id).then(({ data: stock }) => {
        this.stock = stock;
      });
    },

    getCategories() {
      return CategoryService.getAll().then(({ data: categories }) => {
        this.categories = categories;
        this.selectedCategory = this.allCategories[0].name;
      });
    }
  },
  created() {
    this.isLoading = true;

    Promise.all([this.getStock(), this.getCategories()])
      .catch(err => notificationService.error(err.message, err.errors))
      .finally(() => this.isLoading = false);
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.el-tabs__content {
  overflow: visible;
}

.StockLayout {
  margin-top: $global-margin * 2;
  position: relative;

  &__searchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &__search {
    width: 100%;
  }

  &::v-deep .el-table__expanded-cell {
    padding: 0 48px 0 0;
    border: 0;
  }

  &::v-deep .cell {
    word-break: break-word;
    line-height: 1;
  }

  &::v-deep th {
    padding: 5px 0;

    &.header-icon {
      vertical-align: top;

      .cell {
        height: 3em;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 3px;
      }
    }
  }
}
</style>
