<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Frigos connectés</span>
    <span v-if="totalCount > 0" class="PageTitle__count">{{ totalCount }}</span>
  </div>
  <div class="Frigos__main">
    <section class="Frigos__list LineList">
      <div class="LineList__headers">
        <div class="LineList__header">ID</div>
        <div class="LineList__header">Nom du frigo</div>
        <div class="LineList__header">Stock rattaché</div>
        <div class="LineList__header">Code Regate</div>
      </div>
      <Loader v-if="isLoading" />
      <router-link :to="{ name : 'Fridge', params: { id : fridge.id } }" v-else class="Line" v-for="fridge in fridges" :key="fridge.id">
        <div class="fridge-id">#{{ fridge.id }}</div>
        <div class="name">{{ fridge.name }}</div>
        <div class="stock">{{ fridge.buffer_stock ? fridge.buffer_stock.name : "N/A" }}</div>
        <div class="regate-code">{{ fridge.regate_code || "N/A" }}</div>
        <div class="detail-link">
          Voir le détail&nbsp;
          <el-icon :size="12" color="inherit">
            <el-icon-arrow-right-bold />
          </el-icon>
        </div>
      </router-link>
      <pagination v-if="totalCount > 10" v-model="page" :records="totalCount" :perPage="perPage" @paginate="getFridges" />
    </section>
  </div>
</template>

<script>
import Pagination from "v-pagination-3";
import Loader from "../components/Loader";
import notificationService from "../services/notification";
import FridgeService from "../services/fridge";

export default {
  data() {
    return {
      isLoading: false,
      fridges: [],
      page: 1,
      perPage: 10,
      totalCount: 0
    };
  },
  methods: {
    getFridges() {
      this.isLoading = true;

      FridgeService.getAll({ page: this.page })
        .then(({ data: fridges, headers }) => {
          this.fridges = fridges;
          this.perPage = parseInt(headers["page-items"]);
          this.totalCount = parseInt(headers["total-count"]);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    }
  },
  created() {
    this.getFridges();
  },
  components: {
    Pagination,
    Loader
  }
};
</script>

<style lang="scss" scoped>
.LineList__headers,
.Line {
  grid-template-columns: 1fr 3fr 3fr 1fr 3fr;
}

.name {
  font-weight: bold;
}

.router-link-exact-active {
  background: $color-secondary;
}

.Frigos {
  &__main {
    display: flex;
  }

  &__list {
    width: 100%;
    flex-shrink: 0;
    margin-right: 50px;
  }

  &__planogram {
    width: 100%;
  }
}

.detail-link {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
