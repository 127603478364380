<template>
  <template v-if="stock">
    <div class="PageTitle">
      <h1 class="PageTitle__name">{{ stock.name }}</h1>
      <div class="PageTitle__btns">
        <router-link v-if="currentFridge" :to="{name: 'Tours', query: {fridge_id: currentFridge.id, buffer_stock_id: currentFridge.buffer_stock.id} }">Afficher les tournées passées</router-link>
      </div>
    </div>
    <div v-if="stock.fridge_ids.length === 0">
      <el-alert
        showIcon
        :closable="false"
        title="Aucun frigo associé à ce stock"
        type="info"
      />

    </div>
    <template v-else>
      <template v-if="currentFridge">
        <div class="row Tour__navigation">
          <h2 class="Fridge__name">{{ currentFridge.name }}</h2>
          <template v-if="stock.fridge_ids.length > 1">
            <el-button type="info" size="small" :disabled="isLoadingFridge" :loading="isLoadingFridge" @click="prevFridge">Frigo Précédent</el-button>
            <div class="Fridge__count">{{ currentFridgeIndex + 1 }}/{{ stock.fridge_ids.length }}</div>
            <el-button type="info" size="small" :disabled="isLoadingFridge" :loading="isLoadingFridge" @click="nextFridge">Frigo Suivant</el-button>
          </template>
        </div>
        <div class="Tour">
          <div>
            <div class="Tour__header">
              <h3 class="Tour__title">
                Etat actuel
                <!-- TODO -->
                <!-- <span class="Tour__subtitle"> (dernier inventaire le 11/05/21)</span> -->
              </h3>
              <el-button @click="syncFridge" :loading="isSyncLoading" link class="Tour__btn">Mettre à jour depuis Nu!</el-button>
            </div>
            <div class="Fridge">
              <a
                class="Fridge__slot"
                v-for="slot in fridgeSlots"
                :key="slot.id"
              >
                <div class="Fridge__slot-header">
                  <div class="Fridge__slot-dlc" v-if="slot.expiry">{{ expiryFormat(slot.expiry) }}</div>
                  <div class="Fridge__slot-count">{{ slot.quantity }}</div>
                </div>
                <div class="Fridge__slot-name">
                  {{ truncate(slot.label, 35) }}
                  <el-tooltip v-if="slot.product && (!slot.product.pcb || !slot.product.quantity_by_slot)" content="Les informations PCB ou quantité par slot ne sont pas renseignées" placement="top">
                    <el-icon :size="14" color="orange"><el-icon-warning /></el-icon>
                  </el-tooltip>
                </div>
              </a>
              <div class="Fridge__placeholder"></div>
            </div>
          </div>

          <div class="Tour__new-projection" v-if="nextProjections.length === 0">
            <el-card shadow="never">
              <ProjectionCreationForm :disabled="isLoading" @submit="createProjections" />
            </el-card>
          </div>

          <div v-else>
            <div class="Tour__header">
              <h3 class="Tour__title">
                Tournée du {{ dayjs(currentProjection.date).format("DD/MM/YYYY") }}
                <el-icon v-if="currentProjection.locked" class="title-icon">
                  <el-icon-lock />
                </el-icon>
              </h3>
              <el-select size="small" placeholder="choisir la tournée" v-model="selectedTour" @change="changeProjection">
                <el-option v-for="projection in projections" :key="projection.id" :value="projection.date" :label="dayjs(projection.date).format('DD/MM/YYYY')" />
              </el-select>
            </div>
            <div class="Fridge" :class="{'Fridge--disabled': currentProjection.locked }">
              <div
                class="Fridge__slot"
                v-for="(slot, id) in projectionSlots"
                :key="id"
                :class="{'Fridge__slot--empty': slot.quantity === 0, 'Fridge__slot--done': isSlotComplete(slot) }"
                @click="editSlot(slot, id)"
              >
                <div class="Fridge__slot-header">
                  <svg class="check"
                    v-if="isSlotComplete(slot)"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  ><path d="M7.99998 1.3335C4.31998 1.3335 1.33331 4.32016 1.33331 8.00016C1.33331 11.6802 4.31998 14.6668 7.99998 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 7.99998 1.3335ZM6.66665 11.3335L3.33331 8.00016L4.27331 7.06016L6.66665 9.44683L11.7266 4.38683L12.6666 5.3335L6.66665 11.3335Z" fill="currentColor" /></svg>
                  <div class="Fridge__slot-count">{{ slot.quantity }}</div>
                </div>
                <div class="Fridge__slot-name">{{ truncate(slot.label, 40) }}</div>

              </div>
              <div class="Fridge__placeholder"></div>
            </div>
            <div class="Fridge__actions">
              <el-button size="large" @click="showNewProjectionDialog = true">Nouvelle Tournée</el-button>
              <el-tooltip v-if="nextProjections.length > 0 && nextProjections[0].id === currentProjection.id" :disabled="currentProjection.locked" content="Vous devez verrouiller la projection pour exporter la feuille de tournée" placement="bottom">
                <el-button size="large" :disabled="!currentProjection.locked" @click="showExportDialog=true">Exporter
                  <el-icon>
                    <el-icon-download />
                  </el-icon>
                </el-button>
              </el-tooltip>
              <el-button v-if="currentProjection.locked" size="large" type="info" :loading="isLockingProjection" @click="unlockProjection(currentProjection.id)">Déverrouiller cette projection
                <el-icon>
                  <el-icon-unlock />
                </el-icon>
              </el-button>
              <el-button v-if="!currentProjection.locked" size="large" type="info" :loading="isLockingProjection" @click="lockProjection(currentProjection.id)">Verrouiller cette projection
                <el-icon>
                  <el-icon-lock />
                </el-icon>
              </el-button>
            </div>
            <el-dialog width="450px" v-model="showExportDialog" destroyOnClose>
              <el-form labelPosition="top">
                <el-form-item label="Message à destination du livreur">
                  <el-input :rows="6" maxlength="300" showWordLimit type="textarea" v-model="exportText" />
                </el-form-item>
                <div class="align-right">
                  <el-button :disabled="isExporting" @click="showExportDialog = false" type="info">Annuler</el-button>
                  <el-button type="primary" :loading="isExporting" @click="exportProjection">Exporter la Feuille de Tournée</el-button>
                </div>
              </el-form>
            </el-dialog>

            <el-dialog width="450px" v-model="showNewProjectionDialog" destroyOnClose>
              <ProjectionCreationForm @cancel="showNewProjectionDialog = false" :disabled="isLoading" @submit="createProjections" />
            </el-dialog>
            <el-dialog
              title="Provisionner cet emplacement de frigo :"
              width="450px"
              v-model="showEditSlotDialog"
              destroyOnClose
              @closed="currentSlot = null"
            >
              <el-form labelPosition="top">
                <el-form-item label="Séléctionnez le produit pour ce slot :">
                  <el-select v-model="currentSlot.product_id" filterable>
                    <el-option :key="product.id" :label="product.name" :value="product.id" v-for="product in products" />
                  </el-select>
                </el-form-item>
                <el-form-item v-show="currentSlot.quantity_by_slot" :label="`Combien de produit dans le slot ? (Max: ${currentSlot.quantity_by_slot})`">
                  <el-select :disabled="!currentSlot.quantity_by_slot" v-model="currentSlot.quantity">
                    <el-option :key="index" :value="q" v-for="(q, index) in currentSlotEnableQuantity">
                      <span style="float: left;">{{ q }}</span>
                      <span v-if="currentSlotHasSameProduct && q === currentSlot.planogramSlot.quantity" style="float: right; color: #8492a6; font-size: 13px;">(Etat actuel)</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="!currentSlotMaxQuantity && currentSlot.product_id" label="Combien de produits par slot frigo ?">
                  <el-input v-model="currentSlot.quantity_by_slot" />
                </el-form-item>
                <el-form-item v-if="currentSlot.product_id">
                  <el-checkbox v-model="currentSlot.force_update">Forcer le remplacement des produits actuels</el-checkbox>
                </el-form-item>
                <div class="align-right">
                  <el-button :disabled="isLoadingFridge" @click="resetSlot" plain type="info">Revenir à l'état initial</el-button>
                  <el-button :disabled="isLoadingFridge" @click="showEditSlotDialog = false" type="info">Annuler</el-button>
                  <el-button type="primary" :disabled="isLoadingFridge" :loading="isLoadingFridge" @click="saveSlot">Valider</el-button>
                </div>
              </el-form>
            </el-dialog>
          </div>
        </div>
      </template>
      <Loader v-else />
    </template>

    <div class="row">
      <h3 class="Stock__name">{{ stock.name }}</h3>
      <router-link :to="{name: 'StockTourSelect', params: {id: stock.id}}">
        <el-button size="large" type="primary" class="Stock__orderBtn" ref="btn">Nouvelle Commande</el-button>
      </router-link>
    </div>

    <div class="StockLayout">
      <div class="StockLayout__searchContainer">
        <el-input
          clearable
          v-model="search"
          placeholder="Rechercher un produit"
          suffixIcon="el-icon-search"
          class="StockLayout__search"
        />
      </div>
      <el-tabs type="card" v-model="selectedCategory">
        <el-tab-pane
          :label="category.name"
          v-for="category in allCategories"
          :key="category.id"
          :name="category.name"
        >
          <el-table :data="filteredProducts" style="width: 100%;" prop="id">
            <el-table-column label="Éditer" width="100">
              <template #default="table">
                <router-link :to="{name: 'editProduct', params: { id: table.row.product_id }}" tag="span">
                  <el-button link size="small">
                    <el-icon>
                      <el-icon-edit-pen />
                    </el-icon>
                  </el-button>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column label="Nom du produit" prop="name" width="auto">
              <template #default="table">
                <div class="name-with-warning">
                  {{ table.row.name }}
                  <el-tooltip v-if="!table.row.pcb || !table.row.quantity_by_slot" content="Les informations PCB ou quantité par slot ne sont pas renseignées" placement="top">
                    <el-icon :size="18" color="orange"><el-icon-warning /></el-icon>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="Seuil minimum"
              prop="preventive_quantity"
              width="120"
              className="header-icon"
            />
            <el-table-column
              align="center"
              label="Qté réelle"
              prop="quantity"
              width="120"
              className="header-icon incomming-icon"
            />
            <el-table-column
              align="center"
              label="Qté en cours de commande"
              prop="ordered_quantity"
              width="120"
              className="header-icon in-stock-icon"
            />
            <el-table-column
              align="center"
              label="Qté à commander en PCB"
              prop="next_order_quantity"
              width="120"
              className="header-icon outgoing-icon"
            />
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  <Loader v-else />
</template>

<script>
import truncate from "@npm/truncate";
import clone from "@npm/clone";
import dayjs from "dayjs";
import stockService from "../services/stock";
import fridgeService from "../services/fridge";
import productService from "../services/product";
import Loader from "../components/Loader";
import ProjectionCreationForm from "../components/ProjectionCreationForm";
import notificationService from "../services/notification";
import CategoryService from "../services/category";
import expiryFormat from "../utils/expiryFormat";

export default {
  data() {
    return {
      isLoading: false,
      isLoadingFridge: false,
      isLockingProjection: false,
      isSyncLoading: false,
      isExporting: false,
      stock: null,
      currentFridge: null,
      currentSlot: null,
      showNewProjectionDialog: false,
      showEditSlotDialog: false,
      showExportDialog: false,
      products: [],
      categories: [],
      selectedCategory: null,
      dlc: "28/05",
      next_order_dlc: "4",
      quantity_dlc: "4",
      search: "",
      selectedTour: "",
      exportText: ""
    };
  },
  computed: {
    fridgeSlots() {
      return this.getSlots(this.currentFridge.planogram.slots);
    },

    nextProjections() {
      return this.currentFridge.projections.filter(p => dayjs().isBefore(dayjs(p.date)));
    },

    projectionSlots() {
      return this.getSlots(this.currentProjection.slots);
    },

    currentProjection() {
      if (this.currentFridge && this.nextProjections.length > 0) {
        return this.currentFridge.projections.find(p => p.date === this.currentProjectionDate);
      }

      return null;
    },

    projections() {
      return this.currentFridge.projections;
    },

    currentFridgeIndex() {
      return this.stock?.fridge_ids.findIndex(f => f === parseInt(this.$route.query.fridge_id));
    },
    currentProjectionDate() {
      if (!this.currentFridge && this.nextProjections.length === 0) {
        return "";
      }

      if (!this.$route.query.projection_date
        || !this.currentFridge.projections.some(p => p.date === this.$route.query.projection_date)
      ) {
        // on retourne le plus proche dans le futur si le query.projection_date n'est pas valide
        return this.currentFridge.projections.find(p => dayjs().isBefore(dayjs(p.date))).date;
      }

      return this.currentFridge.projections.find(p => p.date === this.$route.query.projection_date).date;
    },

    currentSlotMaxQuantity() {
      return this.products.find(p => this.currentSlot?.product_id === p.id)?.quantity_by_slot;
    },
    currentSlotHasSameProduct() {
      return this.currentSlot.product_id === this.currentSlot.planogramSlot.product?.id;
    },
    currentSlotEnableQuantity() {
      const currentQuantity = this.currentSlotHasSameProduct ? this.currentSlot.planogramSlot.quantity : 0;

      return Array.from({ length: this.currentSlot.quantity_by_slot - currentQuantity + 1 }, (_, i) => i + currentQuantity);
    },
    filteredProducts() {
      if (!this.stock) {
        return [];
      }

      if (this.selectedCategory === "Sans catégories") {
        return this.stock.products.filter(p => p.category === null && p.name.toLowerCase().includes(this.search.toLowerCase()));
      }

      return this.stock.products.filter(
        p => p.category === this.selectedCategory && p.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    allCategories() {
      return [...this.categories, { id: null, name: "Sans catégories" }];
    }
  },
  watch: {
    currentFridgeIndex: {
      handler(newVal, oldVal) {
        if (typeof newVal !== "undefined" && newVal !== -1) {
          this.getFridge(this.stock.fridge_ids[newVal]);
        }
      },
      immediate: true
    },

    currentSlotMaxQuantity: {
      handler(newValue) {
        if (this.currentSlot) {
          this.currentSlot.quantity_by_slot = newValue;
        }
      }
    }
  },
  methods: {
    exportProjection() {
      this.isExporting = true;

      fridgeService.exportProjection(this.currentProjection.id, "pdf", this.exportText)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", `${this.currentProjection.date}-${this.currentFridge.name}.pdf`);
          document.body.appendChild(link);
          link.click();
          this.showExportDialog = false;
        }).finally(() => this.isExporting = false);
    },
    isSlotComplete(slot) {
      return !!slot.product && slot.product.quantity_by_slot && slot.quantity === slot.product.quantity_by_slot;
    },

    nextFridge() {
      const currentIndex = this.stock.fridge_ids.findIndex(f => f === parseInt(this.$route.query.fridge_id));
      const nextFridgeId = currentIndex === this.stock.fridge_ids.length - 1 ? this.stock.fridge_ids[0] : this.stock.fridge_ids[currentIndex + 1];

      this.$router.push({
        to: "Projections",
        params: { id: this.stock.id },
        query: {
          fridge_id: nextFridgeId,
          ...this.$route.query.projection_date ? { projection_date: this.$route.query.projection_date } : {}
        }
      });
    },

    prevFridge() {
      const currentIndex = this.stock.fridge_ids.findIndex(f => f === parseInt(this.$route.query.fridge_id));
      const prevFridgeId = currentIndex === 0 ? this.stock.fridge_ids[this.stock.fridge_ids.length - 1] : this.stock.fridge_ids[currentIndex - 1];

      this.$router.push({
        to: "Projections",
        params: { id: this.stock.id },
        query: {
          fridge_id: prevFridgeId,
          ...this.$route.query.projection_date ? { projection_date: this.$route.query.projection_date } : {}
        }
      });
    },

    getFridge(id) {
      this.isLoadingFridge = true;

      return fridgeService.get(id)
        .then(({ data: fridge }) => this.currentFridge = fridge)
        .then(() => this.selectedTour = this.currentProjection ? this.currentProjection.date : "")
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoadingFridge = false);
    },

    syncFridge() {
      this.isSyncLoading = true;

      fridgeService.syncFridge(this.currentFridge.id)
        .then(() => this.getFridge(this.currentFridge.id))
        .then(() => notificationService.success("Le frigo a été mis à jour"))
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isSyncLoading = false);
    },

    editSlot(slot, index) {
      this.currentSlot = {
        id: slot.id,
        planogramSlot: this.fridgeSlots[index],
        product_id: slot.product ? slot.product.id : "",
        quantity: slot.quantity,
        previousProduct: slot.product ? clone(slot.product) : null,
        previousQuantity: slot.quantity,
        quantity_by_slot: slot.product?.quantity_by_slot || "",
        force_update: slot.force_update
      };

      this.showEditSlotDialog = true;
    },

    saveQuantityBySlot() {
      return new Promise((resolve, reject) => {
        if (!this.currentSlot.product_id || this.currentSlot.quantity_by_slot === this.currentSlotMaxQuantity) {
          resolve();
        } else {
          const data = new FormData();

          data.append("quantity_by_slot", this.currentSlot.quantity_by_slot);

          productService.put(this.currentSlot.product_id, data)
            .then(() => resolve())
            .catch(e => reject(e));
        }
      });
    },

    saveSlot() {
      this.saveQuantityBySlot()
        .then(() => fridgeService.updateSlot(this.currentSlot.id, {
          product_id: this.currentSlot.product_id,
          quantity: this.currentSlot.quantity,
          force_update: this.currentSlot.force_update
        }))
        .then(() => this.getFridge(this.currentFridge.id))
        .then(() => this.showEditSlotDialog = false)
        .catch(err => notificationService.error(err.message, err.errors));
    },

    resetSlot() {
      const planogramSlot = this.currentSlot.planogramSlot;

      this.currentSlot.product_id = planogramSlot.product?.id || "";
      this.currentSlot.quantity = 0;
      this.currentSlot.quantity_by_slot = planogramSlot.product?.quantity_by_slot || "";
      this.currentSlot.force_update = false;
      this.saveSlot();
    },

    createProjections(date) {
      this.isLoading = true;
      this.showNewProjectionDialog = false;

      stockService.createTour(this.stock.id, {
        date: date
      })
        .then(() => {
          this.getFridge(this.currentFridge.id)
            .then(() => {
              // on reroute vers la projection créée pour le frigo courant
              this.$router.push({
                to: "Projections",
                params: { id: this.stock.id },
                query: {
                  fridge_id: this.currentFridge.id,
                  projection_date: date
                }
              });

              this.selectedTour = date;
            });
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },

    getSlots(slots) {
      const output = [];

      for (const i of [0, 1, 2, 3]) {
        for (const j of [0, 1, 2, 3, 4]) {
          const slot = slots.find(s => s.shelf_index === i && s.slot_index === j);

          // empty slot reserved for card payment
          if (i === 2 && j === 0) {
            continue;
          }

          if (slot) {
            output.push({ ...slot, quantity: slot.quantity || 0, label: slot.label || slot.product?.name });
          } else {
            output.push({ label: "", quantity: 0 });
          }
        }
      }

      return output;
    },

    lockProjection(id) {
      this.isLockingProjection = true;

      fridgeService.lockProjection(id)
        .then(({ data: projection }) => this.currentFridge.projections.find(p => p.id === projection.id).locked = true)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLockingProjection = false);
    },
    unlockProjection(id) {
      this.isLockingProjection = true;

      fridgeService.unlockProjection(id)
        .then(({ data: projection }) => this.currentFridge.projections.find(p => p.id === projection.id).locked = false)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLockingProjection = false);
    },
    getCategories() {
      return CategoryService.getAll().then(({ data: categories }) => {
        this.categories = categories;
        this.selectedCategory = this.allCategories[0].name;
      })
        .catch(err => notificationService.error(err.message, err.errors));
    },
    changeProjection() {
      this.$router.push({ name: "Projections", query: { fridge_id: this.currentFridge.id, projection_date: this.selectedTour } });
    },
    truncate,
    dayjs,
    expiryFormat
  },

  created() {
    this.isLoading = true;
    this.getCategories();

    productService.getAllLight()
      .then(({ data: products }) => this.products = products)
      .catch(err => notificationService.error(err.message, err.errors))
      .finally(() => this.isLoading = false);

    stockService.get(this.$route.params.id)
      .then(({ data: stock }) => {
        this.stock = stock;

        if (this.stock.fridge_ids.length > 0) {
          if (!this.$route.query.fridge_id || !this.stock.fridge_ids.includes(parseInt(this.$route.query.fridge_id))) {
            this.$router.push({ to: "Projections", params: { id: this.stock.id }, query: { fridge_id: this.stock.fridge_ids[0] } });
          }
        }
      })
      .catch(err => notificationService.error(err.message, err.errors))
      .finally(() => this.isLoading = false);
  },
  components: {
    Loader,
    ProjectionCreationForm
  }
};
</script>

<style lang="scss" scoped>
.Button {
  margin: 0;
}

.Tooltip {
  float: right;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: #eee;
  border-radius: 50%;
  margin-left: 0.5rem;
}

.Stock {
  &__name {
    margin-right: auto;
  }

  &__orderBtn {
    margin-top: $global-margin * 4;
  }
}

.el-tabs__nav-scroll {
  display: flex;
  justify-content: space-between;
}

.Tour {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    font-size: 1rem;
    margin-bottom: 0.25em;

    .title-icon {
      margin-left: 0.25em;
    }
  }

  &__subtitle {
    color: $color-grey;
    font-weight: normal;
  }

  &__btn {
    border: none;
    background: transparent;
    text-decoration: underline;
    font-size: 0.875em;
    color: $color-grey;
    cursor: pointer;
  }

  &__navigation {
    margin-bottom: 20px;
  }

  &__new-projection {
    margin-top: 40px;
    width: 350px;
  }
}

.Fridge {
  min-width: 420px;
  min-height: 420px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  grid-gap: 20px 10px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 20px;

  &--disabled {
    pointer-events: none;
  }

  &--disabled &__slot {
    box-shadow: 0 1px 2px rgba(#000, 0.1);
  }

  &__placeholder {
    grid-column: 1;
    grid-row: 3;
    background-color: #eee;
    border-radius: 3px;
  }

  &__name {
    margin: 0;
    margin-right: auto;
  }

  &__count {
    color: $color-grey;
    margin: 0 10px;
  }

  &__slot {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 5px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(#000, 0.1), 0 5px 10px rgba(#000, 0.1);
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.1s ease;
    height: 100px;

    &:hover {
      box-shadow: 0 5px 20px rgba(#000, 0.2);
    }

    &--empty > * {
      opacity: 0.5;
    }

    &--done {
      background: mix($color-success, #fff, 5%);
    }

    .check {
      align-self: flex-start;
      color: $color-success;
      margin-right: auto;
    }
  }

  &__slot-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  &__slot-dlc {
    margin-right: 5px;
    font-size: 0.8rem;
    color: $color-grey;
    font-weight: 600;
  }

  &__slot-count {
    align-self: flex-end;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 900;
    color: #fff;
    background: $color-grey;
  }

  &__slot-name {
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__actions {
    text-align: right;
  }
}

.el-tabs__content {
  overflow: visible;
}

.StockLayout {
  margin-top: $global-margin * 2;
  position: relative;

  &__searchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &__search {
    width: 100%;
  }

  &::v-deep .el-table__expanded-cell {
    padding: 0 48px 0 0;
    border: 0;
  }

  &::v-deep .cell {
    word-break: break-word;
    line-height: 1;
  }

  &::v-deep th {
    padding: 5px 0;

    &.header-icon {
      vertical-align: top;

      .cell {
        height: 3em;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 3px;
      }
    }
  }
}

.name-with-warning {
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}

</style>
