import { createRouter, createWebHistory } from "vue-router";
import Orders from "../views/Orders";
import Products from "../views/Products";
import Users from "../views/Users";
import Login from "../views/Login";
import PasswordConfirmation from "../views/PasswordConfirmation";
import EmailConfirmation from "../views/EmailConfirmation";
import PasswordRecovery from "../views/PasswordRecovery";
import Product from "../views/Product";
import Configuration from "../views/Configuration";
import Fridges from "../views/Fridges";
import Fridge from "../views/Fridge";
import Stocks from "../views/Stocks";
import Stock from "../views/Stock";
import StockEdit from "../views/StockEdit";
import StockTourSelect from "../views/StockTourSelect";
import StockOrder from "../views/StockOrder";
import Projections from "../views/Projections";
import Tours from "../views/Tours";
import StockOrders from "../views/StockOrders";
import StockOrderEdit from "../views/StockOrderEdit";
import Categories from "../views/Categories";
import Faq from "../views/Faq";
import Compagnies from "../views/Compagnies";
import Complaints from "../views/Complaints";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Orders",
    component: Orders,
    meta: {
      requiresAuth: true,
      title: "Commandes"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Connexion"
    }
  },
  {
    path: "/password-confirmation/:token",
    name: "PasswordConfirmation",
    component: PasswordConfirmation
  },
  {
    path: "/email-confirmation/:token",
    name: "EmailConfirmation",
    component: EmailConfirmation
  },
  {
    path: "/password-recovery",
    name: "PasswordRecovery",
    component: PasswordRecovery,
    meta: {
      title: "Mot de passe oublié"
    }
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      title: "Utilisateurs"
    }
  }, {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      requiresAuth: true,
      title: "Produits"
    }
  },
  {
    path: "/products/new",
    name: "createProduct",
    component: Product,
    props: { editMode: false },
    meta: {
      requiresAuth: true,
      title: "Création d'un produit"
    }
  },
  {
    path: "/products/:id",
    name: "editProduct",
    component: Product,
    props: { editMode: true },
    meta: {
      requiresAuth: true,
      title: "Edition d'un produit"
    }
  },
  {
    path: "/configuration",
    name: "Configuration",
    component: Configuration,
    meta: {
      requiresAuth: true,
      title: "Configuration de l'application"
    }
  }, {
    path: "/fridges",
    name: "Fridges",
    component: Fridges,
    meta: {
      requiresAuth: true,
      title: "Frigos"
    }
  }, {
    path: "/fridges/:id",
    name: "Fridge",
    component: Fridge,
    meta: {
      requiresAuth: true,
      title: "Frigo"
    }
  }, {
    path: "/stocks",
    name: "Stocks",
    component: Stocks,
    meta: {
      requiresAuth: true,
      title: "Liste des stocks tampon"
    }
  },
  {
    path: "/stocks/:id",
    name: "Stock",
    component: Stock,
    meta: {
      requiresAuth: true,
      title: "Stock tampon"
    }
  },
  {
    path: "/stocks/:id/edit",
    name: "EditStock",
    component: StockEdit,
    meta: {
      requiresAuth: true,
      title: "Edition de stock"
    }
  },
  {
    path: "/stocks/:id/order",
    name: "StockOrder",
    component: StockOrder,
    meta: {
      requiresAuth: true,
      title: "Passage de commande"
    }
  },
  {
    path: "/stocks/:id/tour-select",
    name: "StockTourSelect",
    component: StockTourSelect,
    meta: {
      requiresAuth: true,
      title: "Sélection des tournées"
    }
  },
  {
    path: "/stocks/:id/orders",
    name: "StockOrders",
    component: StockOrders,
    meta: {
      requiresAuth: true,
      title: "Liste des commandes"
    }
  },
  {
    path: "/stocks/:stockId/orders/:orderId",
    name: "StockOrderEdit",
    component: StockOrderEdit,
    meta: {
      requiresAuth: true,
      title: "Modification de commande"
    }
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: {
      requiresAuth: true,
      title: "Catégories"
    }
  }, {
    path: "/stocks/:id/projections",
    name: "Projections",
    component: Projections,
    meta: {
      requiresAuth: true,
      title: "Création d'une tournée"
    }
  },
  {
    path: "/tours",
    name: "Tours",
    component: Tours,
    meta: {
      requiresAuth: true,
      title: "Tournées"
    }
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
    meta: {
      requiresAuth: true,
      title: "Faq"
    }
  },
  {
    path: "/compagnies",
    name: "Compagnies",
    component: Compagnies,
    meta: {
      requiresAuth: true,
      title: "Compagnies"
    }
  },
  {
    path: "/complaints",
    name: "Complaints",
    component: Complaints,
    meta: {
      requiresAuth: true,
      title: "Réclamations"
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const authRouteNames = ["Login", "PasswordRecovery", "PasswordConfirmation"];

  const checkAuth = () => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters["auth/isLoggedIn"]) {
        next();

        return;
      }

      next({ name: "Login", query: { redirect: to.path } });
    } else if (
      authRouteNames.includes(to.name)
      && store.getters["auth/isLoggedIn"]
    ) {
      next({ name: "Orders" });
    } else {
      next();
    }
  };

  if (store.state.initialized) {
    checkAuth();

    return;
  }

  store.dispatch("initialize").then(() => {
    checkAuth();
  });
});

export default router;
