<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Produits</span>
    <span v-if="totalCount > 0" class="PageTitle__count">{{ totalCount }}</span>
    <div class="PageTitle__btns">
      <a href="#" @click.prevent="updateNuInfos" class="Button Button--secondary PageTitle__btn" name="sync">Synchronisation Nu!</a>
      <router-link :to="{name: 'createProduct'}" class="Button PageTitle__btn">Nouveau produit</router-link>
    </div>
  </div>
  <div class="Filter">
    <span class="Filter__title">
      Filtrer par
    </span>
    <el-form :inline="true" :model="formFilter">
      <el-form-item label="Nom/Description" class="Filter__group">
        <el-input
          clearable
          v-model="formFilter.query"
          suffixIcon="el-icon-search"
          class="search-input"
          @keyup.enter="searchProducts"
          @clear="searchProducts"
        />
      </el-form-item>
      <el-form-item label="Etat" class="Filter__group state-filter">
        <el-select @change="searchProducts" v-model="formFilter.state" clearable placeholder="Tous les états">
          <el-option label="Actif" value="1" />
          <el-option label="Inactif" value="2" />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
  <section class="LineList">
    <div class="LineList__headers">
      <div class="LineList__header"><!-- thumbnail --></div>
      <div class="LineList__header">Nom/Description</div>
      <div class="LineList__header justify--end">NU!REF</div>
      <div class="LineList__header justify--center">Poids</div>
      <div class="LineList__header justify--center">Calories</div>
      <div class="LineList__header justify--center">Catégorie</div>
      <div class="LineList__header justify--center">Etat</div>
      <div class="LineList__header"><!-- edit --></div>
    </div>
    <Loader v-if="isLoading" />
    <template v-else>
      <div class="LineList__empty" v-if="products.length === 0">Aucun produit à ce jour.</div>
      <div class="Line" v-for="product in products" :key="product.id">
        <div class="picture">
          <div class="thumbnail" :style="thumbnailStyle(product.list_thumbnail)"></div>
        </div>
        <div class="infos">
          <router-link :to="{ name : 'editProduct', params: { id : product.id } }" class="name">{{ product.name }}</router-link>
          <div v-if="product.description" class="description">{{ truncate(product.description, 100) }}</div>
        </div>
        <div class="nu-id justify--end">{{ product.nu_ref }}</div>
        <div class="weight justify--center">{{ product.weight ? `${product.weight}g`: "N/A" }}</div>
        <div class="calories justify--center">{{ product.nutritional_facts.calorie_intake_kcal_portion ? `${energyFormat(product.nutritional_facts.calorie_intake_kcal_portion)}kcal`: "N/A" }}</div>
        <div class="category justify--center">{{ product.category ? product.category.name : "N/A" }}</div>
        <div class="state justify--center">{{ product.active ? "Actif" : "Inactif" }}</div>
        <router-link :to="{ name : 'editProduct', params: { id : product.id } }" class="edit justify--end">
          <el-icon>
            <el-icon-edit-pen />
          </el-icon>
        </router-link>
      </div>
    </template>
    <pagination v-if="totalCount > 10" v-model="page" :records="totalCount" :perPage="perPage" @paginate="getProducts" />
  </section>
</template>

<script>
import truncate from "@npm/truncate";
import Pagination from "v-pagination-3";
import productService from "../services/product";
import priceFormat from "../utils/priceFormat";
import energyFormat from "../utils/energyFormat";
import Loader from "../components/Loader";
import notificationService from "../services/notification";

export default {
  name: "Products",
  data() {
    return {
      isLoading: false,
      products: [],
      page: 1,
      perPage: 10,
      totalCount: 0,
      formFilter: {
        query: "",
        state: "1"
      }
    };
  },
  methods: {
    truncate,
    priceFormat,
    energyFormat,
    getProducts() {
      this.isLoading = true;

      return productService.getAll({ page: this.page,
        ...this.formFilter.query ? { query: this.formFilter.query } : {},
        ...this.formFilter.state ? { active: this.formFilter.state === "1" ? true : false } : {}
      })
        .then(({ data: products, headers }) => {
          this.products = products;
          this.perPage = parseInt(headers["page-items"]);
          this.totalCount = parseInt(headers["total-count"]);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },

    searchProducts() {
      this.page = 1;
      this.getProducts();
    },

    updateNuInfos() {
      this.isLoading = true;

      productService.getNuInfosByBatch()
        .then(() => this.getProducts())
        .finally(() => this.isLoading = false);
    },
    thumbnailStyle(thumbnail) {
      if (thumbnail && thumbnail.big) {
        return {
          backgroundImage: `url(${process.env.VUE_APP_API_URL + thumbnail.big})`
        };
      }
    }
  },
  created() {
    this.getProducts();
  },
  components: {
    Loader,
    Pagination
  }

};
</script>

<style lang="scss" scoped>
.LineList__headers,
.Line {
  grid-template-columns: 65px 5fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.justify--center {
  justify-self: center;
}

.justify--end {
  justify-self: end;
}

.thumbnail {
  width: 60px;
  height: 60px;
  border-radius: $global-radius;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #efefef;
}

.name {
  font-weight: bold;
  margin-bottom: 2px;
}

.frigo {
  font-weight: bold;
  justify-self: center;
}

.description {
  color: $color-grey;
  font-size: 0.9rem;
  width: 80%;
}

.search-input {
  width: 250px;
}

.state-filter {
  width: 170px;
}

.Filter {
  padding: 0.5em;
  display: flex;
  align-items: baseline;
  background-color: lighten($color-grey, 50%);
  margin-bottom: 20px;

  &__title {
    width: 70px;
    margin-right: 1em;
    font-size: 14px;
    font-weight: 800;
  }

  &__group {
    color: $color-black;
    margin-bottom: 0;
  }
}

</style>
