import axios from "axios";
import Api from "./api";

class ComplaintService extends Api {
  exportAll(params) {
    return axios({
      url: `${this.apiVersion}${this.ressource}.xlsx`,
      method: "GET",
      responseType: "blob",
      params
    });
  }
}

export default new ComplaintService({
  ressource: "/admin/support_messages"
});
