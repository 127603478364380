import axios from "axios";
import Api from "./api";

class ConfigurationService extends Api {
  get() {
    return axios({ url: `${this.apiVersion}${this.ressource}`, method: "GET" });
  }
}

export default new ConfigurationService({
  ressource: "/admin/config"
});
