<template>
  <div>
    <section class="Section">
      <form @submit.prevent="recovery">
        <div class="InputGroup">
          <label class="InputGroup__label">Email</label>
          <Input id="email" type="email" required placeholder="user@domain.com" v-model="email" name="email" />
        </div>
        <Button type="submit" :loading="loading" name="reset.password">Réinitialiser mon mot de passe</Button>
      </form>
    </section>
  </div>
</template>

<script>
import userService from "../services/user";
import notificationService from "../services/notification";
import Input from "../components/Input";
import Button from "../components/Button";

export default {
  data() {
    return {
      email: "",
      loading: false
    };
  },
  methods: {
    recovery: function() {
      this.loading = true;

      userService.recoverPassword({ email: this.email })
        .then(() => {
          this.$router.push("/login");
          notificationService.success("Si votre e-mail existe dans notre base de données, vous allez recevoir un lien de réinitialisation par e-mail");
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.loading = false);
    }
  },
  components: { Input, Button }
};
</script>
