import { createStore } from "vuex";
import auth from "./auth";

export default createStore({
  modules: {
    auth
  },
  state: {
    initialized: false
  },

  mutations: {
    initialize(state) {
      state.initialized = true;
    }
  },

  actions: {
    initialize({ dispatch, commit }) {
      return dispatch("auth/getUser")
        .then(() => commit("initialize"))
        .catch(() => commit("initialize"));
    }
  }
});
