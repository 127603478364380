// import Vue from "vue";
import { ElNotification } from "element-plus";
import { formatError } from "../utils/formatError";

export default {
  error(message = "", errors, options = { delay: 8000 }) {
    ElNotification.error({
      title: message,
      message: errors ? formatError(errors) : "",
      duration: options.delay,
      dangerouslyUseHTMLString: true
    });
  },
  success(message, options = { delay: 3000 }) {
    ElNotification.success({
      message,
      duration: options.delay
    });
  }
};
