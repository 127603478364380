<template>
  <div
    class="imagePreviewWrapper"
    :style="{ 'background-image': `url(${previewImage})` }"
    @click="selectImage"
  >
    <div class="overlay" :class="{'overlay--new': !previewImage}">{{ overlayText }}</div>
  </div>

  <input
    ref="fileInput"
    type="file"
    @input="pickFile"
  />
</template>

<script>
export default {
  emits: ["update"],
  props: {
    image: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      previewImage: null
    };
  },
  computed: {
    overlayText() {
      return this.previewImage ? "Modifier cette image" : "Choisir une image";
    }
  },
  watch: {
    image(value) {
      this.previewImage = process.env.VUE_APP_API_URL + value;
    }
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      const input = this.$refs.fileInput;
      const file = input.files;

      if (file && file[0]) {
        const reader = new FileReader();

        reader.onload = e => {
          this.previewImage = e.target.result;
        };

        reader.readAsDataURL(file[0]);
        this.$emit("update", file[0]);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.imagePreviewWrapper {
  position: relative;
  border-radius: $global-radius;
  width: 100px;
  flex-grow: 2;
  height: 200px;
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  border: 1px solid $color-border;
  border-radius: $global-radius;

  &:hover {
    border-color: darken($color-border, 10%);
  }

  & .overlay {
    border-radius: $global-radius;
  }
}

input[type="file"] {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;

  &--new {
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
