<template>
  <section class="LoginPage Section">

    <div class="Brand">
      <img
        class="Brand__illustration"
        src="../assets/images/logo.png"
        alt="Marie logo"
      />
      <span>FrigoConnecté</span>
    </div>

    <form @submit.prevent="login" class="LoginForm">
      <h1 class="PageTitle">Connexion</h1>
      <div class="InputGroup">
        <label class="InputGroup__label">Email</label>
        <Input :required="true" autocomplete="email" v-model="email" placeholder="Identifiant" name="email" />
      </div>
      <div class="InputGroup">
        <label class="InputGroup__label">Password</label>
        <PasswordInput :required="true" v-model="password" placeholder="Mot de passe" />
      </div>
      <Button :loading="status==='loading'" type="submit" class="Button--full" name="login">Je me connecte</Button>
      <router-link class="forgotten-password" :to="{name: 'PasswordRecovery'}">Mot de passe oublié ?</router-link>
    </form>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Input from "../components/Input";
import PasswordInput from "../components/PasswordInput";
import Button from "../components/Button";
import notificationService from "../services/notification";

export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapState("auth", ["status"])
  },
  methods: {
    login: function() {
      const email = this.email;
      const password = this.password;

      this.$store.dispatch("auth/login", { email, password })
        .then(() => {
          const redirect = this.$route.query.redirect;

          this.$router.replace(redirect ? redirect : "/");
        })
        .catch(err => notificationService.error(err.message, err.errors));
    }
  },
  components: { Input, PasswordInput, Button }
};
</script>

<style lang="scss" scoped>
.LoginPage {
  min-height: 98vh;
  align-content: center;
}

.Brand {
  margin: 3.75rem auto 2.5rem;
  min-height: 6rem;

  span {
    font-weight: bold;
  }
}

.PageTitle {
  margin-bottom: 2.5rem;
}

.LoginForm {
  width: 100%;
  max-width: 22em;
  margin: auto;
}

.forgotten-password {
  margin-top: 2rem;
  display: block;
  font-size: 0.875rem;
  color: $color-primary;
  text-decoration: underline;
  text-align: center;
}

</style>
