<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Modification de commande</span>
  </div>
  <template v-if="order">
    <div class="PageTitle__row">
      <router-link :to="{ name: 'StockOrders', params: { id: $route.params.stockId }}"
        class="PageTitle__link"
      >
        <el-icon class="PageTitle__icon">
          <el-icon-back />
        </el-icon>
        Retourner à la liste des commandes
      </router-link>

      <div class="PageTitle__subheading">
        <b>{{ dateFormat(order.date, false) }}</b><br />
        Stock concerné : {{ order.buffer_stock }}
      </div>
    </div>
    <div class="StockLayout">
      <el-table :data="order.lines" style="width: 100%;" prop="id">
        <el-table-column prop="product_name" label="Nom du produit" width="auto" />
        <el-table-column label="PCB commandés" prop="pcb_to_order" width="120" align="center" />
        <el-table-column label="PCB commandés ajustés" width="120" align="center">
          <template #default="scope">
            <Input
              class="StockLayout__input"
              :value="scope.row.fixed_pcb"
              @blur="(e) => saveOrderLine(scope.row, e.target.value, scope.row.received_pcb)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="PCB reçus"
          width="120"
          align="center"
        >
          <template #default="scope">
            <Input
              class="StockLayout__input"
              :value="scope.row.received_pcb"
              @blur="(e) => saveOrderLine(scope.row, scope.row.fixed_pcb, e.target.value)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="Qté en stock" width="120" align="center" />
      </el-table>
    </div>
  </template>
</template>

<script>
import StockService from "../services/stock";
import notificationService from "../services/notification";
import Input from "../components/Input";
import dateFormat from "../utils/dateFormat";

export default {
  data() {
    return {
      order: null,
      isLoading: false,
      isOrderLineLoading: false
    };
  },
  methods: {
    getOrder() {
      StockService.getOrder(this.$route.params.orderId)
        .then(({ data: order }) => {
          this.order = order;
        })
        .catch(err => notificationService.error(err.message, err.errors));
    },

    saveOrderLine(line, fixed_pcb, received_pcb) {
      if (parseInt(fixed_pcb) === line.fixed_pcb && parseInt(received_pcb) === line.received_pcb) {
        return;
      }

      this.isOrderLineLoading = true;

      StockService.saveOrderLine(line.id, fixed_pcb, received_pcb)
        .then(() => {
          this.isOrderLineLoading = false;
          this.getOrder();
          notificationService.success("La commande a été mise à jour");
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => {
          this.isOrderLineLoading = false;
        });
    },
    dateFormat
  },
  created() {
    this.getOrder();
  },
  components: {
    Input
  }
};
</script>

<style lang="scss" scoped>

.PageTitle {
  &__btn {
    color: $color-grey;
    text-decoration: underline;
  }

  &__row {
    margin-top: $global-margin * 2;
  }

  &__link {
    display: flex;
    color: $color-grey;
  }

  &__icon {
    margin-right: $global-margin;
  }

  &__subheading {
    margin-top: $global-margin * 2;
    font-weight: 500;
    line-height: 1.5;
  }
}

.StockLayout {
  margin-top: $global-margin * 2;

  &__input {
    width: 40px;
    height: 24px;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
  }

  &__btns {
    margin-top: $global-margin * 2;
    float: right;
    display: flex;
  }

  &__icon {
    color: $color-black;
  }

  &::v-deep .cell {
    word-break: break-word;
    line-height: 1.2;
  }
}
</style>
