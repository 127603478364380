<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Compagnies</span>
    <span v-if="companies.length > 0" class="PageTitle__count">{{ companies.length }}</span>
    <div class="PageTitle__btns">
      <el-button size="large" type="primary" @click.prevent="displayNewForm">Nouvelle compagnie</el-button>
    </div>
  </div>
  <div class="Compagnies__main" v-if="!isLoading">
    <el-table :data="companies">
      <el-table-column label="ID" prop="id" width="50">
        <template #default="scope">
          <span>#{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Compagnie" prop="name" width="250" />
      <el-table-column label="Nom(s) de domaine" prop="authorized_domains" width="350" />
      <el-table-column label="Frigos">
        <template #default="scope">
          <span v-for="(f, index) in scope.row.fridges" :key="f.id">
            {{ index > 0 ? ", ": "" }}{{ f.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre d’utilisateurs" prop="user_count" align="center" />
      <el-table-column label="Éditer" width="100" align="center">
        <template #default="scope">
          <el-icon class="edit-icon" @click="displayEditForm(scope.row)"><el-icon-edit-pen /></el-icon>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      width="500px"
      :title="current.id ? 'Mettre à jour la compagnie' : 'Créer une nouvelle compagnie'"
      v-if="current"
      v-model="displayCompanyDialog"
      @closed="onDialogClosed"
    >
      <el-form labelPosition="top" @submit.prevent="save" class="Form">
        <el-form-item label="Nom">
          <el-input v-model="current.name" />
        </el-form-item>
        <el-form-item label="Noms de domaine">
          <el-tag
            v-for="tag in current.authorized_domains"
            :key="tag"
            class="domain-tag"
            type="info"
            closable
            :disableTransitions="false"
            @close="removeDomain(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            v-if="inputVisible"
            ref="InputRef"
            style="width: 140px"
            v-model="domainInput"
            class="ml-1 w-20"
            size="small"
            placeholder="mondomaine.fr"
            @keyup.enter="addDomain"
            @blur="addDomain"
          />
          <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput">
            + Ajouter
          </el-button>
        </el-form-item>
        <el-form-item label="Frigo(s) attribué(s)">
          <el-select
            v-model="current.fridge_ids"
            multiple
            style="width: 100%"
          >
            <el-option
              v-for="f in fridges"
              :key="f.id"
              :label="f.name"
              :value="f.id"
            />
          </el-select>
        </el-form-item>
        <el-divider />
        <div class="align-right">
          <el-button :disabled="isSaving" type="info" @click="displayCompanyDialog = false">Annuler</el-button>
          <el-button :loading="isSaving" type="primary" @click="save">Enregistrer</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
  <Loader v-else />
</template>

<script>
import Loader from "../components/Loader";
import notificationService from "../services/notification";
import CompanyService from "../services/company";
import FridgeService from "../services/fridge";

export default {
  data() {
    return {
      isLoading: false,
      isSaving: false,
      companies: [],
      current: null,
      displayCompanyDialog: false,
      inputVisible: false,
      domainInput: "",
      fridges: []
    };
  },
  methods: {
    getCompanies() {
      this.isLoading = true;

      CompanyService.getAll()
        .then(({ data: companies }) => this.companies = companies)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getFridges() {
      this.isLoading = true;

      return FridgeService.getAll()
        .then(({ data: fridges }) => this.fridges = fridges)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    displayNewForm() {
      this.current = {
        name: "",
        fridge_ids: [],
        authorized_domains: []
      };

      this.displayCompanyDialog = true;
    },
    displayEditForm(c) {
      this.current = {
        ...c,
        authorized_domains: c.authorized_domains.split(","),
        fridge_ids: c.fridges.map(f => f.id)
      };

      this.displayCompanyDialog = true;
    },
    showInput() {
      this.inputVisible = true;

      this.$nextTick(() => {
        this.$refs.InputRef.focus();
      });
    },
    removeDomain(tag) {
      this.current.authorized_domains = this.current.authorized_domains.filter(t => t !== tag);
    },
    addDomain() {
      // test if the input is a valid domain like "example.com"
      if (this.domainInput && this.domainInput.match(/^[^.]+\.[^.]+$/)) {
        this.current.authorized_domains.push(this.domainInput);
        this.domainInput = "";
        this.inputVisible = false;
      }
    },
    save() {
      let request;

      this.isSaving = true;

      if (this.current.id) {
        request = CompanyService.put(this.current.id, {
          ...this.current,
          authorized_domains: this.current.authorized_domains.join(",")
        }, { "Content-Type": "application/json" });
      } else {
        request = CompanyService.post({
          ...this.current,
          authorized_domains: this.current.authorized_domains.join(",")
        }, { "Content-Type": "application/json" });
      }

      request.then(() => {
        this.getCompanies();
        this.displayCompanyDialog = false;
      })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isSaving = false);
    },
    onDialogClosed() {
      this.current = null;
      this.inputVisible = false;
      this.domainInput = "";
    }
  },
  created() {
    this.getCompanies();
    this.getFridges();
  },
  components: {
    Loader
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.edit-icon {
  cursor: pointer;
}

.domain-tag {
  margin-right: 5px;
}

</style>
