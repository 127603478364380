import * as Sentry from "@sentry/vue";

export default function(app, router) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category.startsWith("ui")) {
        const target = hint.event.target;
        const customName = target.closest("[data-sentry]")?.dataset.sentry || target.title || target.name;
        const customMessage = `${target.tagName.toLowerCase()}(${customName})`;

        breadcrumb.message = customName ? customMessage : breadcrumb.message;
      }

      return breadcrumb;
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });

  if (process.env.VUE_APP_SENTRY_ENVIRONMENT !== "production") {
    return;
  }

  fetch("./version.json")
    .then(res => res.json())
    .then(({ revision }) => {
      console.info("Sentry will throw error from release : " + revision);

      Sentry.configureScope(scope => scope.addEventProcessor(
        event => new Promise(resolve => resolve({
          ...event,
          release: revision
        }))
      ));
    }).catch(() => Sentry.captureMessage("Something went wrong with the 'release' capture"));
}
