import { Factory } from "miragejs";
import faker from "faker";

export default Factory.extend({
  name() {
    return faker.commerce.productName();
  },
  last_update() {
    return "date";
  },
  address() {
    return "address";
  }

});
