import axios from "axios";
import qs from "qs";
import Api from "./api";

class UserService extends Api {
  login(user) {
    return axios({ url: "/users/sign_in", data: user, method: "POST" });
  }
  logout() {
    return axios({ url: "/users/sign_out", method: "DELETE" });
  }
  resetPassword(user) {
    return axios({ url: "/users/password", data: { user }, method: "PUT" });
  }
  recoverPassword(user) {
    return axios({ url: "/users/password", data: { user }, method: "POST" });
  }
  confirmEmail(token) {
    return axios({ url: "/users/confirmation", params: { confirmation_token: token }, method: "GET" });
  }
  getUser() {
    return axios({ url: `/${this.apiVersion}/user`, method: "GET" });
  }
  updateUser() {
    return axios({ url: `/${this.apiVersion}/user`, method: "PUT" });
  }
  exportAll(filters) {
    return axios({
      url: `${this.apiVersion}/admin/users.xlsx`,
      method: "GET",
      responseType: "blob",
      params: filters,
      paramsSerializer: params => qs.stringify(filters, {
        arrayFormat: "brackets",
        skipNulls: true,
        filter: (prefix, value) => value !== "" ? value : undefined
      })
    });
  }
}

export default new UserService({
  ressource: "/admin/users"
});
