<template>
  <div class="PageTitle">
    <h1 class="PageTitle__name">Toutes les tournées</h1>
  </div>

  <div class="Tour__header">
    <div class="Tour__stock-form">
      <label class="Tour__label">Filtrer par stock tampon</label>
      <el-select v-model="selectedStockId" :disabled="isLoading" size="large">
        <el-option
          v-for="s in stocks"
          :key="s.id"
          :value="s.id"
          :label="s.name"
        />
      </el-select>
    </div>
    <div class="Tour__stock-form">
      <label class="Tour__label">Filtrer par frigo</label>
      <el-select v-model="selectedFridgeId" :disabled="isLoading" size="large">
        <el-option
          v-for="fridge in fridgeOptions"
          :key="fridge.id"
          :value="fridge.id"
          :label="fridge.name"
        />
      </el-select>
    </div>
  </div>
  <div class="Tour__main">
    <div class="PageTitle__subtitle Tour__subtitle">Tournées à faire</div>
    <el-table style="width: 100%;" :data="futureTours" v-if="!isLoading">
      <el-table-column label="Date"
        width="120"
      ><template #default="scope">{{
        dayjs(scope.row.date).format("DD/MM/YYYY")
      }}</template>
      </el-table-column>

      <el-table-column align="left"
        width="auto"
        label="Nom du produit"
      />
      <el-table-column align="center"
        label="Frigo"
        width="200"
      />
      <el-table-column
        align="center"
        label="Qté PCB à commander"
        width="200"
      ><template #default="scope">{{ getTotalProduct(scope.row) }}</template>
      </el-table-column>
      <el-table-column type="expand">
        <template #default="scope">
          <el-table style="width: 100%;" size="small" :data="getProducts(scope.row)">
            <el-table-column width="70" />
            <el-table-column
              prop="productName"
              width="auto"
            />
            <el-table-column
              prop="fridgeName"
              align="center"
              width="220"
            />
            <el-table-column
              prop="quantityToOrder"
              align="center"
              width="210"
            />
          </el-table>
        </template>
      </el-table-column>
    </el-table>
    <Loader v-else />
  </div>
  <div class="Tour__main">
    <div class="PageTitle__subtitle Tour__subtitle">Tournées passées</div>
    <el-table style="width: 100%;" :data="pastTours" v-if="!isLoadingPastTours">
      <el-table-column label="Date"
        width="120"
      ><template #default="scope">{{
        dayjs(scope.row.date).format("DD/MM/YYYY")
      }}</template>
      </el-table-column>

      <el-table-column align="left"
        width="auto"
        label="Nom du produit"
      />
      <el-table-column align="center"
        label="Frigo"
        width="200"
      />
      <el-table-column
        align="center"
        label="Qté PCB à commander"
        width="200"
      ><template #default="scope">{{ getTotalProduct(scope.row) }}</template>
      </el-table-column>

      <el-table-column width="60">
        <template #default="">
          <el-button link>
            <el-icon class="Tour__icon">
              <el-icon-download />
            </el-icon>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column type="expand">
        <template #default="scope">
          <el-table style="width: 100%;" size="small" :data="getProducts(scope.row)">
            <el-table-column width="60" />
            <el-table-column
              prop="productName"
              width="auto"
            />
            <el-table-column
              prop="fridgeName"
              width="200"
              align="center"
            />
            <el-table-column
              prop="quantityToOrder"
              width="200"
              align="center"
            />
            <el-table-column width="60" />
          </el-table>
        </template>
      </el-table-column>
    </el-table>
    <div class="Tour__pagination" v-if="totalCount > perPage">
      <el-pagination
        :hideOnSinglePage="true"
        :total="totalCount"
        :pageSize="perPage"
        @current-change="changePage"
        layout="prev, pager, next"
      />
    </div>

    <Loader v-if="isLoadingPastTours" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import StockService from "../services/stock";
import notificationService from "../services/notification";
import FridgeService from "../services/fridge";
import Loader from "../components/Loader";

export default {
  data() {
    return {
      dialogFormVisible: false,
      isLoading: false,
      isLoadingPastTours: false,
      fridges: [],
      pastTours: [],
      futureTours: [],
      selectedStockId: "",
      selectedFridgeId: "",
      date: "",
      currentPage: 1,
      perPage: 10,
      totalCount: 0
    };
  },
  computed: {
    fridgeOptions() {
      return [{ id: 0, name: "Tous les frigos" }, ...this.fridges.filter(f => f.buffer_stock && f.buffer_stock.id === this.selectedStockId)];
    },
    stocks() {
      const stocks = this.fridges.filter(f => !!f.buffer_stock).map(f => f.buffer_stock);

      return [...stocks.reduce((acc, obj) => acc.set(obj.id, obj), new Map()).values()];
    }
  },
  methods: {
    getTours() {
      this.currentPage = 1;
      this.getFuturesTours();
      this.getPastTours(this.currentPage);
      this.$router.push({ name: "Tours", query: { fridge_id: this.selectedFridgeId, buffer_stock_id: this.selectedStockId } });
    },
    getFuturesTours() {
      this.isLoading = true;

      const fridge_id = this.selectedFridgeId ? this.selectedFridgeId : null;

      return StockService.getTours(this.selectedStockId, { future: true, fridge_id })
        .then(({ data: tours }) => this.futureTours = tours)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getPastTours(page) {
      this.isLoadingPastTours = true;

      const fridge_id = this.selectedFridgeId ? this.selectedFridgeId : null;

      return StockService.getTours(this.selectedStockId, { past: true, page, fridge_id })
        .then(({ data: tours, headers }) => {
          this.pastTours = tours;
          this.perPage = parseInt(headers["page-items"]);
          this.totalCount = parseInt(headers["total-count"]);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoadingPastTours = false);
    },
    getFridges() {
      this.isLoading = true;

      return FridgeService.getAll()
        .then(({ data: fridges }) => this.fridges = fridges)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getTotalProduct(tour) {
      return tour.projections.filter(p => p.locked)
        .reduce((acc, p) => acc.concat(p.slots.filter(s => s.quantity_to_order > 0)), [])
        .reduce((acc, slot) => acc + slot.quantity_to_order, 0);
    },
    getProducts(tour) {
      return tour.projections.filter(p => p.locked)
        .reduce((acc, p) => acc.concat(p.slots.filter(s => s.quantity_to_order > 0).map(s => ({ ...s, fridgeName: p.fridge.name }))), [])
        .map(slot => ({
          productName: slot.product.name,
          fridgeName: slot.fridgeName,
          quantityToOrder: slot.quantity_to_order

        }))
        .reduce((acc, product) => {
          const existingProduct = acc.find(p => p.productName === product.productName && p.fridgeName === product.fridgeName);

          if (existingProduct) {
            existingProduct.quantityToOrder += product.quantityToOrder;

            return acc;
          }

          acc.push(product);

          return acc;
        }, []).sort((a, b) => {
          if (a.fridgeName > b.fridgeName) {
            return 1;
          }

          if (a.fridgeName < b.fridgeName) {
            return -1;
          }

          return 0;
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.getPastTours(this.currentPage);
    },
    dayjs
  },
  created() {
    this.getFridges()
      .then(() => {
        this.selectedStockId = this.$route.query.buffer_stock_id ? parseInt(this.$route.query.buffer_stock_id) : this.stocks[0].id;
        this.selectedFridgeId = this.$route.query.fridge_id ? parseInt(this.$route.query.fridge_id) : 0;

        this.getFuturesTours();
        this.getPastTours(this.currentPage);

        this.$watch("selectedStockId", () => {
          if (this.selectedFridgeId) {
            this.selectedFridgeId = 0;

            return;
          }

          this.getTours();
        });

        this.$watch("selectedFridgeId", () => {
          this.getTours();
        });
      });
  },

  components: {
    Loader
  }
};
</script>

<style lang="scss" scoped>
.Tour {
  &__header {
    display: flex;
    justify-content: space-evenly;
  }

  &__label {
    margin-right: $global-margin;
    font-weight: bold;
  }

  &__main {
    margin-top: $global-margin * 3;
  }

  &__icon {
    color: $color-grey;
    font-weight: bold;
  }

  &__pagination {
    text-align: center;
    margin-top: 20px;
  }

  &__subtitle {
    margin-bottom: $global-margin * 2;
  }
}
</style>
