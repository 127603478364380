<template>
  <div class="multi-text">
    <div class="multi-text__row" v-for="(item, index) in values" :key="index">
      <el-input size="large" :placeholder="placeholder" v-model="values[index]" @input="updateValue()" />
      <a class="multi-text__delete" href="#" @click="deleteValue(index)">
        <el-icon :size="20"><el-icon-delete-filled /></el-icon>
      </a>
    </div>
    <a class="multi-text__more" href="#" @click="addValue()">{{ moreLabel }}</a>
  </div>
</template>

<script>
export default {
  emits: ["update"],
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    moreLabel: {
      type: String,
      default: "Ajouter"
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return { values: [] };
  },
  methods: {
    updateValue: function() {
      this.$emit("update:modelValue", this.values);
    },
    deleteValue: function(index) {
      this.values.splice(index, 1);
      this.$emit("update:modelValue", this.values);
    },
    addValue: function() {
      this.values.push("");
      this.$emit("update:modelValue", this.values);
    }
  },
  created: function() {
    this.values = Array.from(this.modelValue);
  }
};
</script>

<style scoped  lang="scss">
.multi-text {
  margin-bottom: 10px;
  width: 100%;

  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
  }

  &__delete {
    margin-left: 10px;
    cursor: pointer;
  }

  &__more {
    float: right;
    text-decoration: underline;
    cursor: pointer;
  }

}
</style>
