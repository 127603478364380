<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Configuration</span>
  </div>
  <div class="subtitle"></div>
  <div class="Form">
    <div class="Form__main">
      <div class="Form__col">
        <div class="InputGroup">
          <label class="InputGroup__label">Conditions Générales d'Utilisation</label>
          <FileUpload
            :file="configuration.cgu"
            @update="newfile => cguFile = newfile"
          />
        </div>
      </div>
      <div class="Form__col">
        <div class="InputGroup">
          <label class="InputGroup__label">Conditions Générales de Vente</label>
          <FileUpload
            :file="configuration.cgv"
            @update="newfile => cgvFile = newfile"
          />
        </div>
      </div>
    </div>
    <div class="Form__actions">
      <Button :disabled="isLoading" @click="save" name="save">Enregistrer</Button>
    </div>
  </div>
</template>

<script>
import configurationService from "../services/configuration";
import FileUpload from "../components/fileUpload";
import Button from "../components/Button";
import notificationService from "../services/notification";

export default {
  data() {
    return {
      configuration: {},
      isLoading: false,
      cguFile: null,
      cgvFile: null
    };
  },
  methods: {
    save() {
      this.isLoading = true;

      const data = new FormData();

      if (this.cguFile) {
        data.set("cgu", this.cguFile);
      }

      if (this.cgvFile) {
        data.set("cgv", this.cgvFile);
      }

      configurationService.post(data)
        .then(({ data: configuration }) => this.configuration = configuration)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    }
  },
  created() {
    configurationService.get()
      .then(({ data: configuration }) => this.configuration = configuration)
      .catch(err => notificationService.error(err.message, err.errors))
      .finally(() => this.isLoading = false);
  },
  components: {
    Button,
    FileUpload
  }
};
</script>

<style lang="scss" scoped>
.Product {
  display: flex;

  &__col {
    width: 50%;
    padding: $global-margin * 2;
    flex-grow: 0;
    border-bottom: 1px solid $color-smoke-border;
    margin-bottom: $global-margin * 2;
  }
}

.InputGroup {
  flex-direction: row;

  &__label {
    width: 180px;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: $global-margin * 0.5;
    text-align: right;
    padding-right: $global-margin;
  }
}

.Button {
  margin-left: auto;
}

.subtitle {
  color: $color-grey;
  margin-bottom: $global-margin * 2;
  font-style: italic;
}

.name,
.allergens,
.nu-label {
  height: 70px;
}

.description,
.ingredients {
  height: 120px;
}

.weight,
.calories {
  text-align: right;
}

.error {
  font-style: italic;
}
</style>
