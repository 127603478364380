<template>
  <template v-if="stock">
    <div class="PageTitle">
      <h1 class="PageTitle__name">Commandes du stock tampon {{ stock.name }}</h1>
      <div class="PageTitle__btns">
        <router-link :to="{name: 'StockTourSelect', params: {id: stock.id}}">
          <el-button type="primary">
            Nouvelle commande
          </el-button>
        </router-link>
      </div>
    </div>

    <div class="Orders__main">
      <div class="PageTitle__subtitle">Commandes en cours</div>
      <el-table style="width: 100%;" :data="pendingOrders" v-if="!isLoadingPendingOrders">
        <el-table-column label="Date"
          width="120"
        ><template #default="scope">{{
          dayjs(scope.row.date).format("DD/MM/YYYY")
        }}</template>
        </el-table-column>

        <el-table-column align="left"
          label="Nom du produit"
          width="auto"
        ><template #default="scope">{{ scope.row.buffer_stock.name }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          label="PCB"
          width="200"
        ><template #default="scope">{{ getTotalPcb(scope.row) }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          label="Quantité"
          width="200"
        ><template #default="scope">{{ getTotalProduct(scope.row) }}</template>
        </el-table-column>

        <el-table-column width="40">
          <template #default="scope">
            <router-link :to="{name: 'StockOrderEdit', params: {stockId: stock.id, orderId: scope.row.id}}">
              <el-button link>
                <el-icon class="Orders__icon">
                  <el-icon-edit-pen />
                </el-icon>
              </el-button>
            </router-link>
          </template>
        </el-table-column>

        <el-table-column width="60">
          <template #default="">
            <el-button link>
              <el-icon class="Orders__icon">
                <el-icon-download />
              </el-icon>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template #default="scope">
            <el-table style="width: 100%;" :data="scope.row.lines" :showHeader="false">
              <el-table-column width="120" />
              <el-table-column
                align="left"
                prop="product_name"
                width="auto"
              />
              <el-table-column
                prop="fixed_pcb"
                align="center"
                width="200"
              />
              <el-table-column
                prop="fixed_quantity"
                align="center"
                width="200"
              />
              <el-table-column width="148" />
            </el-table>
          </template>
        </el-table-column>
      </el-table>
      <Loader v-else />
      <div class="Orders__main">
        <div class="PageTitle__subtitle">Commandes passées</div>
        <el-table style="width: 100%;" :data="pastOrders" size="small" v-if="!isLoadingPastOrders">
          <el-table-column label="Date"
            width="120"
          ><template #default="scope">{{
            dayjs(scope.row.date).format("DD/MM/YYYY")
          }}</template>
          </el-table-column>
          <el-table-column align="left"
            label="Nom du produit"
            width="auto"
          ><template #default="scope">{{
            scope.row.buffer_stock.name
          }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            label="PCB"
            width="200"
          ><template #default="scope">{{ getTotalPcb(scope.row) }}</template>
          </el-table-column>

          <el-table-column
            align="center"
            label="Quantité"
            width="200"
          ><template #default="scope">{{ getTotalProduct(scope.row) }}</template>
          </el-table-column>
          <el-table-column width="40">
            <template #default="scope">
              <router-link :to="{name: 'StockOrderEdit', params: {stockId: stock.id, orderId: scope.row.id}}">
                <el-button link>
                  <el-icon class="Orders__icon">
                    <el-icon-edit-pen />
                  </el-icon>
                </el-button>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column width="60">
            <template #default="">
              <el-button link>
                <el-icon class="Orders__icon">
                  <el-icon-download />
                </el-icon>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column type="expand">
            <template #default="scope">
              <el-table style="width: 100%;" :data="scope.row.lines" :showHeader="false">
                <el-table-column width="120" />
                <el-table-column
                  align="left"
                  prop="product_name"
                  width="auto"
                />
                <el-table-column
                  prop="fixed_pcb"
                  align="center"
                  width="200"
                />
                <el-table-column
                  prop="fixed_quantity"
                  align="center"
                  width="200"
                />
                <el-table-column width="148" />
              </el-table>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </template>
  <div class="Orders__pagination" v-if="totalCount > perPage">
    <el-pagination
      :hideOnSinglePage="true"
      :total="totalCount"
      :pageSize="perPage"
      @current-change="changePage"
      layout="prev, pager, next"
    />
  </div>
  <Loader v-if="isLoadingPastOrders" />
</template>

<script>
import dayjs from "dayjs";
import stockService from "../services/stock";
import Loader from "../components/Loader";
import notificationService from "../services/notification";

export default {
  data() {
    return {
      isLoadingPendingOrders: false,
      isLoadingPastOrders: false,
      pastOrders: [],
      stock: null,
      pendingOrders: [],
      currentPage: 1,
      perPage: 10,
      totalCount: 0
    };
  },
  methods: {
    getPendingOrders(stockId) {
      this.isLoadingPendingOrders = true;

      return stockService.getOrders(stockId, { in_progress: true })
        .then(({ data: orders }) => this.pendingOrders = orders)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoadingPendingOrders = false);
    },
    getPastOrders(stockId, page) {
      this.isLoadingPastOrders = true;

      return stockService.getOrders(stockId, { past: true, page })
        .then(({ data: orders, headers }) => {
          this.pastOrders = orders;
          this.perPage = parseInt(headers["page-items"]);
          this.totalCount = parseInt(headers["total-count"]);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoadingPastOrders = false);
    },
    changePage(page) {
      this.currentPage = page;
      this.getPastOrders(this.stock.id, this.currentPage);
    },
    getTotalPcb(order) {
      return order.lines.reduce((acc, line) => acc + line.fixed_pcb, 0);
    },
    getTotalProduct(order) {
      return order.lines.reduce((acc, line) => acc + line.fixed_quantity, 0);
    },
    dayjs
  },
  created() {
    stockService.get(this.$route.params.id)
      .then(({ data: stock }) => {
        this.stock = stock;
      })
      .catch(err => notificationService.error(err.message, err.errors));

    this.getPendingOrders(this.$route.params.id);
    this.getPastOrders(this.$route.params.id, this.currentPage);
  },
  components: {
    Loader
  }
};
</script>

<style lang="scss" scoped>
.Orders {
  &__main {
    margin-top: $global-margin * 3;
  }

  &__icon {
    color: $color-grey;
    font-weight: bold;
  }
}
</style>
