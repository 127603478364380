<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Réclamations</span>
    <span v-if="totalCount > 0" class="PageTitle__count">{{ totalCount }}</span>
    <div class="PageTitle__btns">
      <el-button type="primary" plain @click="exportComplaints">Exporter en XLS</el-button>
    </div>
  </div>
  <div class="Filter">
    <span class="Filter__title">
      Filtrer
    </span>
    <el-form :inline="true" :model="formFilter">
      <el-form-item label="Utilisateur" class="Filter__group user-filter">
        <el-input v-model="userInput" @blur="setUserFilter" @keypress.enter="setUserFilter" />
      </el-form-item>
      <el-form-item label="Frigo" class="Filter__group state-filter">
        <el-select v-model="formFilter.fridge_id" clearable placeholder="Tous">
          <el-option v-for="f in fridges" :label="f.name" :key="f.id" :value="f.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="Etat" class="Filter__group state-filter">
        <el-select v-model="formFilter.closed" clearable placeholder="Tous">
          <el-option label="Ouvert" value="1" />
          <el-option label="Fermée" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="Date de création" class="Filter__group">
        <div class="Filter__date-picker">
          <el-date-picker
            v-model="formFilter.date"
            format="DD/MM/YYYY"
            valueFormat="YYYY-MM-DD"
            type="daterange"
            startPlaceholder="Début"
            endPlaceholder="Fin"
          />
        </div>
      </el-form-item>
    </el-form>
  </div>
  <section>
    <Loader v-if="isLoading" />
    <el-table v-else :data="complaints" @row-click="showComplaint" style="width:100%">
      <el-table-column label="ID" prop="id" width="50">
        <template #default="scope">
          <span>#{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Utilisateur" width="250">
        <template #default="scope">
          <el-button @click.stop="contactUser(scope.row)" link>
            <el-icon :size="16" class="message"><el-icon-message /></el-icon>
            {{ scope.row.user.full_name }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Type de réclamation" prop="type" width="200" />
      <el-table-column label="Date/heure">
        <template #default="scope">
          {{ dateFormat(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column label="Frigo" prop="fridge_name" width="150" />
      <el-table-column label="Etat">
        <template #default="scope">
          <span v-if="scope.row.closed_at" class="state state--closed">Fermée</span>
          <span v-else class="state state--open">Ouverte</span>
        </template>
      </el-table-column>
      <el-table-column label="Cloturé le" width="auto">
        <template #default="scope">
          {{ scope.row.closed_at ? dateFormat(scope.row.closed_at) : "" }}
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template #default="scope">
          <el-icon v-if="!scope.row.closed_at" :size="24" class="toogle" @click.stop="toggleClosed(scope.row)"><el-icon-check-filled /></el-icon>
        </template>
      </el-table-column>

    </el-table>
    <div class="pagination" v-if="totalCount > perPage">
      <el-pagination
        :hideOnSinglePage="true"
        :total="totalCount"
        :pageSize="perPage"
        :currentPage="page"
        @current-change="changePage"
        layout="prev, pager, next"
      />
    </div>
  </section>
  <el-dialog
    title="Détail de la réclamation"
    v-model="showComplaintDialog"
    width="30%"
  >
    <el-form
      labelPosition="top"
    >
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="Utilisateur :" position="top">
            <el-input readonly :value="currentComplaint.user.full_name" />
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="Email :">
            <el-input readonly :value="currentComplaint.user.email" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="Date/heure :">
            <el-input readonly :value="dateFormat(currentComplaint.date)" />
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="Type de réclamation :">
            <el-input readonly :value="currentComplaint.type" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="Contenu de la réclamation :">
        <el-input type="textarea" rows="8" :value="currentComplaint.content" />
      </el-form-item>
    </el-form>

    <div slot="footer" class="align-right">
      <el-button plain :disabled="isLoadingState" type="primary" @click="toggleClosed(currentComplaint)">{{ currentComplaint.closed_at ? "Rouvrir" : "Clore" }} cette réclamation</el-button>
      <el-button @click="contactUser(currentComplaint)" type="primary">Répondre</el-button>
    </div>
  </el-dialog>

</template>

<script>
import complaintService from "../services/complaint";
import fridgeService from "../services/fridge";
import dateFormat from "../utils/dateFormat";
import Loader from "../components/Loader";
import notificationService from "../services/notification";

export default {
  data() {
    return {
      isLoading: false,
      isLoadingState: false,
      fridges: [],
      complaints: [],
      currentComplaint: null,
      showComplaintDialog: false,
      page: 1,
      perPage: 20,
      userInput: "",
      totalCount: 0,
      formFilter: {
        closed: "",
        user: "",
        date: []
      }
    };
  },
  computed: {
    filterQueries() {
      return {
        page: this.page,
        ...this.formFilter.user ? { user: this.formFilter.user } : {},
        ...this.formFilter.fridge_id ? { fridge_id: this.formFilter.fridge_id } : {},
        ...this.formFilter.closed ? { closed: this.formFilter.closed } : {},
        ...this.formFilter.date && this.formFilter.date[0] ? { from: this.formFilter.date[0] } : {},
        ...this.formFilter.date && this.formFilter.date[1] ? { to: this.formFilter.date[1] } : {}
      };
    }
  },
  watch: {
    "$route.query": function() {
      this.isLoading = true;
      this.getComplaints();
    },
    formFilter: {
      handler() {
        this.isLoading = true;
        this.changePage(1);
      },
      deep: true
    }
  },
  methods: {
    dateFormat,
    getComplaints() {
      complaintService.getAll({
        ...this.filterQueries,
        ...this.filterQueries.closed ? { closed: this.formFilter.closed === "1" ? false : true } : {}
      })
        .then(({ data: complaints, headers }) => {
          this.complaints = complaints;
          this.perPage = parseInt(headers["page-items"]);
          this.totalCount = parseInt(headers["total-count"]);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },

    changePage(p) {
      this.page = p;
      this.$router.push({ to: "Complaints", query: this.filterQueries });
    },
    getStateFromUrlQueries() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;

      this.formFilter = {
        user: this.$route.query.user ?? "",
        fridge_id: this.$route.query.fridge_id ?? "",
        date: [this.$route.query.from ?? "", this.$route.query.to ?? ""],
        closed: this.$route.query.closed ?? ""

      };
    },
    toggleClosed(complaint) {
      if (this.isLoadingState) {
        return;
      }

      this.isLoadingState = true;

      complaintService.put(complaint.id, {
        closed: !complaint.closed_at
      }, { "Content-Type": "application/json" }
      ).then(({ data: complaint }) => {
        this.complaints = this.complaints.map(c => c.id === complaint.id ? complaint : c);

        if (this.currentComplaint) {
          this.currentComplaint = complaint;
        }
      }).finally(() => this.isLoadingState = false);
    },
    setUserFilter() {
      this.formFilter.user = this.userInput;
    },
    showComplaint(row) {
      this.currentComplaint = row;
      this.showComplaintDialog = true;
    },
    contactUser(complaint) {
      window.open("mailto:" + complaint.user.email + "?subject=Le Frigo Marie - Réclamation - " + complaint.type);
    },
    exportComplaints() {
      const { page, ...params } = this.filterQueries; // eslint-disable-line 

      complaintService.exportAll({
        ...params,
        ...params.closed ? { closed: this.formFilter.closed === "1" ? false : true } : {}
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");

          const filename =
            response.headers["content-disposition"]?.split("filename=")[1]?.split(";")[0]
            ?? "complaints.xlsx";

          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => notificationService.error("Erreur lors de l'export des réclamations"));
    },
    getFridges() {
      this.isLoading = true;

      return fridgeService.getAll()
        .then(({ data: fridges }) => this.fridges = fridges)
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    }
  },
  created() {
    this.isLoading = true;
    this.getStateFromUrlQueries();
    this.getFridges().then(() => this.getComplaints());
  },
  components: {
    Loader
  }
};
</script>
<style lang="scss" scoped>

.pagination {
  text-align: center;
  margin-top: 20px;
}

.Filter {
  padding: 0.5em;
  display: flex;
  align-items: baseline;
  background-color: lighten($color-grey, 50%);

  &__title {
    margin-right: 1em;
    font-size: 14px;
    font-weight: 800;
  }

  &__group {
    color: $color-black;
    margin-bottom: 0;
    width: 25%;
  }

  &__date-picker {
    background-color: white;
  }
}

.state {
  font-style: italic;

  &--closed {
    color: $color-label-red
  }

   &--open {
    color: $color-label-green
  }
}

.toogle {
  cursor: pointer;
}

.message {
  margin-right: 5px;
}

.user-filter {
  width: 220px;
}

.state-filter {
  width: 150px;
}
</style>
