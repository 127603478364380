<template>
  <div class="PageTitle">
    <div class="PageTitle__name">Passage de commande</div>
  </div>
  <div class="PageTitle__row">
    <router-link :to="{ name: 'Projections' }" class="PageTitle__link">
      <el-icon class="PageTitle__icon">
        <el-icon-back />
      </el-icon>
      Retourner au provisionnement des frigos
    </router-link>

    <template v-if="fridges.length === 0">
      <el-alert
        class="no-fridge-alert"
        :closable="false"
        title="Aucune projection vérouillée en attente de commande"
        type="warning"
      />
    </template>
    <template v-else>

      <div class="PageTitle__subheading">
        Sélectionner les tournées pour lesquelles passer commande :
      </div>

      <el-alert
        class="fridge-alert"
        :closable="false"
        title="Pour être sélectionable une projection doit être verrouillée."
        type="warning"
      />

      <Loader v-if="isLoading" />
      <template v-else>
        <template v-if="fridges.length > 0">
          <div class="list-header">
            <div class="list-header-item completion">Complétion des frigos</div>
            <div class="list-header-item date">Date de la tournée</div>
            <div class="list-header-item edit">Modifier</div>
          </div>
          <div v-for="f in fridges" :key="f.id">
            <div class="fridge-name">{{ f.name }}</div>
            <div class="projection-line" :class="checkedTours.includes(p.id) ? 'projection-line--selected' : ''" v-for="p in projectionsByFridge[f.id]" :key="p.id">
              <el-checkbox :checked="checkedTours.includes(p.id)" :value="p.id" @change="(e) => toggleProjection(p.id)" />
              <div class="percent">{{ p.completion_percentage }}%</div>
              <div class="date">{{ dateFormat(p.date, false) }}</div>
              <router-link class="edit" :to="{ name: 'Projections', params: { 'id': $route.params.id}, query: {fridge_id: f.id, projection_date: p.date }}">
                <div>
                  <el-icon><el-icon-edit-pen /></el-icon>
                </div>
              </router-link>
            </div>
          </div>
          <div class="footer">
            <router-link :to="{name: 'StockOrder', params: {id: $route.params.id}, query: {'projection_ids' : JSON.stringify(checkedTours)}}">
              <el-button :disabled="checkedTours.length === 0" type="primary" class="Stock__orderBtn" ref="btn">Nouvelle Commande</el-button>
            </router-link>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import StockService from "../services/stock";
import notificationService from "../services/notification";
import Loader from "../components/Loader";
import dateFormat from "../utils/dateFormat";

export default {
  data() {
    return {
      isLoading: false,
      tours: [],
      checkedTours: this.$route.query.projection_ids ? JSON.parse(this.$route.query.projection_ids) : []
    };
  },
  computed: {
    fridges: function() {
      return [...new Map(this.projections.map(p => p.fridge).map(v => [v.id, v])).values()];
    },
    projections: function() {
      return this.tours.flatMap(t => t.waiting_order_projections).filter(p => p.locked);
    },
    projectionsByFridge() {
      return this.projections
        .reduce((acc, p) => {
          if (p.fridge.id in acc) {
            acc[p.fridge.id].push(p);
          } else {
            acc[p.fridge.id] = [p];
          }

          return acc;
        }, {});
    }
  },
  methods: {
    toggleProjection(id) {
      if (this.checkedTours.includes(id)) {
        this.checkedTours = this.checkedTours.filter(t => t !== id);
      } else {
        this.checkedTours = [...this.checkedTours, id];
      }
    },
    dateFormat
  },
  created() {
    this.isLoading = true;

    StockService.getTours(this.$route.params.id, { future: true })
      .then(({ data: tours }) => this.tours = tours)
      .catch(err => notificationService.error(err.message, err.errors))
      .finally(() => this.isLoading = false);
  },
  components: {
    Loader
  }
};
</script>

<style lang="scss" scoped>
.PageTitle {
  &__btn {
    color: $color-grey;
    text-decoration: underline;
  }

  &__row {
    margin-top: $global-margin * 2;
  }

  &__link {
    display: flex;
    color: $color-grey;
  }

  &__icon {
    margin-right: $global-margin;
  }

  &__subheading {
    margin: $global-margin * 2 0;
  }
}

.list-header {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: $color-grey;
  padding: 10px 20px 10px 50px;

  .date {
    margin-left: 65px;
  }

  .edit {
    margin-left: auto;
  }
}

.fridge-name {
  background: #f9f9f9;
  border-top: 1px solid $color-smoke-border;
  padding: 6px 20px;
  font-weight: bold;
  font-size: 0.9rem;
}

.fridge-alert {
  margin-bottom: 20px;
}

.no-fridge-alert {
  margin-top: 20px;
}

.projection-line {
  display: flex;
  font-weight: bold;
  padding: 10px 20px;
  border-bottom: 1px solid $color-smoke-border;
  align-items: center;

  &--selected {
    background: $color-secondary;
  }

  .percent {
    margin-left: 15px;
    width: 50px;
  }

  .date {
    margin-left: 150px;
  }

  .edit {
    margin-left: auto;
    cursor: pointer;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
