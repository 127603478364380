<template>
  <div class="Loader gradient">

  </div>
</template>

<style lang="scss" scoped>
.Loader {
  height: 2px;
}

.gradient {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, $color-primary 8%, $color-primary 10%, #fafafa 24%);
  background-size: 90% 2px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
