<template>
  <div class="App" :class="{'App--logged' : isLoggedIn}">
    <MenuNav class="App__menu" v-if="isLoggedIn" />
    <main class="App__main">
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import store from "./store";
import MenuNav from "./components/Menu";

export default {
  name: "App",

  computed: {
    ...mapGetters("auth", ["isLoggedIn"])
  },
  watch: {
    "$route": {
      handler(to, from) {
        document.title = to.meta.title ? `${to.meta.title} - FrigoCo Admin` : "FrigoCo Admin";
      },
      immediate: true
    }
  },
  created: function() {
    this.$http.interceptors.response.use(undefined, ({ response }) => new Promise((resolve, reject) => {
      if (response.status === 401 && response.config && !response.config.__isRetryRequest && this.isLoggedIn) {
        store.dispatch("auth/logout");
        this.$router.replace({ name: "Login" });
      }

      if (response.status === 404) {
        this.$router.replace({ name: "NotFound" });

        return;
      }

      let error;

      if (response.status !== 500 && response.data) {
        error = { ...response.data, status: response.status };

        throw error;
      }

      error = { message: "Erreur inattendue" };

      throw error;
    }));
  },
  components: {
    MenuNav
  }
};
</script>

<style lang="scss">
.App {
  &--logged {
    display: flex;
    min-height: 100vh;
  }
  &__main{
    padding: 40px;
    flex-grow: 2;
  }
}
/* hides "close" button */

</style>
