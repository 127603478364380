<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Utilisateurs</span>
    <span v-if="totalCount > 0" class="PageTitle__count">{{ totalCount }}</span>
    <div class="PageTitle__btns">
      <el-button type="primary" plain @click="exportUsers">Exporter en XLSX</el-button>
    </div>
  </div>
  <div class="Filter">
    <span class="Filter__title">
      Filtrer par
    </span>
    <el-form :inline="true" :model="formFilter">
      <el-form-item label="Frigo" class="Filter__group">
        <el-select v-model="formFilter.fridgeId" clearable placeholder="Tous les frigos">
          <el-option v-for="fridge in fridges" :key="fridge.id" :label="fridge.name" :value="fridge.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="Etat" class="Filter__group">
        <el-select v-model="formFilter.state" clearable placeholder="Tous les états">
          <el-option v-for="option in stateOptions" :key="option.label" :label="option.label" :value="option.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="Date de création" class="Filter__group">
        <div class="Filter__date-picker">
          <el-date-picker
            v-model="formFilter.date"
            format="DD/MM/YYYY"
            valueFormat="YYYY-MM-DD"
            type="daterange"
            startPlaceholder="Début"
            endPlaceholder="Fin"
          />
        </div>
      </el-form-item>
    </el-form>
  </div>

  <section class="LineList">
    <div class="LineList__headers">
      <div class="LineList__header">ID</div>
      <div class="LineList__header">Prénom / Nom</div>
      <div class="LineList__header">Email</div>
      <div class="LineList__header">Date</div>
      <div class="LineList__header">Etat</div>
      <div class="LineList__header">Notif.</div>
      <div class="LineList__header">Actus<br />Promos</div>
      <div class="LineList__header">Frigo</div>
      <div class="LineList__header">Com-<br />mandes</div>
      <div class="LineList__header order-price">Prix Total</div>
    </div>
    <Loader v-if="isLoading" />
    <div v-else class="Line" v-for="user in users" :key="user.id">
      <div class="user-id">#{{ user.id }}</div>
      <div class="user-name">{{ user.full_name }}</div>
      <div class="email">{{ user.email }}</div>
      <div class="date">{{ dateFormat(user.created_at) }}</div>
      <div
        class="state"
        :class="{
          'is-confirmed': user.status === 'confirmed',
          'is-deleted': user.status === 'deleted'
        }"
      >
        {{ userStatuses[user.status] }}
      </div>
      <div class="notification">
        <el-icon>
          <el-icon-select color="green" v-if="user.notification_accepted" />
          <el-icon-close-bold color="red" v-else />
        </el-icon>
      </div>
      <div class="marketing-agreement">
        <el-icon>
          <el-icon-select color="green" v-if="user.marketing_agreement" />
          <el-icon-close-bold color="red" v-else />
        </el-icon>
      </div>
      <div class="fridge">{{ user.fridge?.name }}</div>
      <div class="order-count">{{ user.order_count }}</div>
      <div class="order-price">{{ user.amount_spent }}€</div>
    </div>
    <pagination v-if="totalCount > 10" v-model="page" :records="totalCount" :perPage="perPage" @paginate="getUsers" />
  </section>
</template>

<script>
import Pagination from "v-pagination-3";
import userService from "../services/user";
import fridgeService from "../services/fridge";
import dateFormat from "../utils/dateFormat";
import dict from "../utils/dict";
import Loader from "../components/Loader";
import notificationService from "../services/notification";

export default {
  data() {
    return {
      isLoading: false,
      users: [],
      page: 1,
      perPage: 10,
      totalCount: 0,
      formFilter: {
        fridgeId: "",
        status: "",
        date: []
      },
      fridges: []
    };
  },
  computed: {
    userStatuses() {
      return dict.userStatuses;
    },
    stateOptions() {
      return Object.keys(this.userStatuses).map(s => ({
        label: this.userStatuses[s],
        value: s
      }));
    },
    filterParams() {
      return {
        fridge_id: this.formFilter.fridgeId,
        status: this.formFilter.state,
        from: this.formFilter.date ? this.formFilter.date[0] : "",
        to: this.formFilter.date ? this.formFilter.date[1] : ""
      };
    }
  },
  watch: {
    formFilter: {
      handler() {
        this.isLoading = true;
        this.page = 1;
        this.getUsers();
      },
      deep: true
    }
  },
  methods: {
    dateFormat,
    getUsers() {
      userService.getAll({ page: this.page, ...this.filterParams })
        .then(({ data: users, headers }) => {
          this.users = users;
          this.perPage = parseInt(headers["page-items"]);
          this.totalCount = parseInt(headers["total-count"]);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getFridges() {
      fridgeService.getAll()
        .then(({ data }) => {
          this.fridges = data;
        })
        .catch(err => notificationService.error(err.message, err.errors));
    },
    exportUsers() {
      userService.exportAll({ page: this.page, ...this.filterParams })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");

          const filename =
            response.headers["content-disposition"]?.split("filename=")[1]?.split(";")[0]
            ?? "users.xlsx";

          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => notificationService.error("Erreur lors de l'export des utilisateurs"));
    }
  },
  created() {
    this.isLoading = true;
    this.getUsers();
    this.getFridges();
  },
  components: {
    Pagination,
    Loader
  }
};
</script>
<style lang="scss" scoped>
.LineList__headers,
.Line {
  grid-template-columns: minmax(45px, 0.6fr) minmax(140px, 2fr) 3fr minmax(90px, 1.5fr) minmax(60px, 1fr) minmax(40px, 0.8fr) minmax(50px, 0.8fr) minmax(80px, 0.8fr) minmax(40px, 0.6fr) minmax(60px, 0.6fr);
}

.LineList__headers {
  font-size: 0.7rem;

  @media (min-width: 1240px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1400px) {
    font-size: 0.9rem;
  }
}
.Line {
  font-size: 0.8rem;

  @media (min-width: 1240px) {
    font-size: 0.9rem;
  }

  @media (min-width: 1400px) {
    font-size: 1rem;
  }
}

.user-name {
  font-weight: bold;
}

.state {
  font-style: italic;
  color: orange;
  font-size: 12px;

  &.is-confirmed {
    color: green;
  }

  &.is-deleted {
    color: red;
  }
}

.notification,
.marketing-agreement {
  text-align: center;
}

.date {
  font-size: 14px;
}

.order-count {
  text-align: center;
}

.order-price {
  text-align: right;
}

.Filter {
  padding: 0.5em;
  display: flex;
  align-items: baseline;
  background-color: lighten($color-grey, 50%);

  &__title {
    width: 70px;
    margin-right: 1em;
    font-size: 14px;
    font-weight: 800;
  }

  &__group {
    color: $color-black;
    margin-bottom: 0;
    width: 18%;
  }

  &__date-picker {
    background-color: white;
    height: 32px;
    border-radius: 4px;
  }
}

</style>
