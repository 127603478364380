<template>
  <div class="PageTitle" v-if="!isLoading">
    <span class="PageTitle__name">{{ stock.name }}</span>
    <div class="PageTitle__btns">
      <router-link :to="{ name: 'StockTourSelect', params: { id: stock.id } }"
        class="Button PageTitle__btn"
      >Nouvelle commande</router-link>
    </div>
  </div>
  <div class="StockLayout">
    <el-table :data="products" maxHeight="70vh" style="width: 100%;" prop="id">
      <el-table-column label="Éditer" width="100">
        <template #default="table">
          <router-link :to="{name: 'editProduct', params: { id: table.row.product_id }}" tag="span">
            <el-button link size="small">
              <el-icon><el-icon-edit-pen /></el-icon>
            </el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Nom du produit" width="auto" />
      <el-table-column label="Stock de couverture" width="120" align="center">
        <template #default="scope">
          <Input
            class="StockLayout__input"
            v-model="scope.row.preventive_quantity"
            name="preventive_quantity"
          />
        </template>
      </el-table-column>
      <el-table-column label="Qté Réelle" width="120" align="center">
        <template #default="scope">
          <Input
            class="StockLayout__input"
            v-model="scope.row.quantity"
            name="quantity"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Qté en cours de commande"
        width="120"
        align="center"
      >
        <template #default="scope">
          <Input
            class="StockLayout__input"
            v-model="scope.row.ordered_quantity"
            name="ordered_quantity"
          />
        </template>
      </el-table-column>
      <el-table-column prop="pcb" label="Qté PCB" width="120" align="center" />
    </el-table>

    <div class="StockLayout__btns">
      <button
        size="large"
        class="Button Button--light PageTitle__btn"
        @click="resetStockData && $router.go(-1)"
      >
        Annuler
      </button>
      <el-button
        size="large"
        class="Button Button--secondary PageTitle__btn"
        type="info"
        :loading="isLoading"
        :disabled="isLoading"
        @click="editStock"
      >
        Modifier l'état du stock
      </el-button>
    </div>
  </div>
</template>

<script>
import clone from "@npm/clone";
import StockService from "../services/stock";
import notificationService from "../services/notification";
import Input from "../components/Input";

export default {
  data() {
    return {
      stock: null,
      products: [],
      isLoading: false
    };
  },
  methods: {
    getStock() {
      this.isLoading = true;

      StockService.get(this.$route.params.id)
        .then(({ data: stock }) => {
          this.stock = stock;
          this.products = clone(stock.products);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    editStock() {
      this.isLoading = true;

      StockService.put(
        this.stock.id,
        this.products.map(p => ({
          id: parseInt(p.id),
          quantity: parseInt(p.quantity),
          preventive_quantity: parseInt(p.preventive_quantity),
          ordered_quantity: parseInt(p.ordered_quantity)
        })),
        { "Content-Type": "application/json" }
      )
        .then(() => notificationService.success("Le stock tampon a été modifié")
        )
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    resetStockData() {
      this.products = clone(this.stock.products);
    }
  },
  created() {
    this.getStock();
  },
  components: {
    Input
  }
};
</script>

<style lang="scss" scoped>
.StockLayout {
  margin-top: $global-margin * 2;

  &__input {
    width: 40px;
    height: 24px;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
  }

  &__btns {
    margin-top: $global-margin * 2;
    float: right;
    display: flex;
  }

  &::v-deep .cell {
    word-break: break-word;
    line-height: 1.2;
  }
}
</style>
