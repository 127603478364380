import axios from "axios";
import qs from "qs";

export default class Api {
  constructor(options = {}) {
    this.ressource = options.ressource;
    this.apiVersion = options.apiVersion || process.env.VUE_APP_API_VERSION;
  }

  get(id) {
    return axios({ url: `${this.apiVersion}${this.ressource}/${id}`, method: "GET" });
  }
  getAll(filters = {}) {
    return axios({
      url: `${this.apiVersion}${this.ressource}`,
      params: filters,
      paramsSerializer: params => qs.stringify(filters, {
        arrayFormat: "brackets",
        skipNulls: true,
        filter: (prefix, value) => value !== "" ? value : undefined
      }),
      method: "GET"
    });
  }
  post(data = {}, headers = { "Content-Type": "multipart/form-data" }) {
    return axios({
      url: `${this.apiVersion}${this.ressource}`,
      headers,
      data,
      method: "POST"
    });
  }
  put(id, data = {}, headers = { "Content-Type": "multipart/form-data" }) {
    return axios({
      url: `${this.apiVersion}${this.ressource}/${id}`,
      headers,
      data,
      method: "PUT"
    });
  }
  delete(id) {
    return axios({
      url: `${this.apiVersion}${this.ressource}/${id}`,
      method: "DELETE"
    });
  }
}
