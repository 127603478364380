import { Factory } from "miragejs";
import faker from "faker";

export default Factory.extend({
  products() {
    return [{
      "quantity": faker.random.number(),
      "product": {
        "id": 1,
        "description": "lorem",
        "images": [],
        "price": 300,
        "ingredients": "string",
        "weight": faker.random.number(),
        "nu_ref": "string",
        "icon": "string",
        "allergen": "string",
        "name": faker.commerce.productName(),
        "list_thumbnail": "string",
        "discover_thumbnail": "string",
        "combo_id": 0,
        "combo_name": "string",
        "combo_price": 0
      }
    }];
  },
  "user_id": 0,
  "user_name": "David Moore",
  "status": {
    "raw_name": "ReviewPending",
    "name": "En cours",
    "infos": "Notre équipe analyse la transaction (environ 24h)."
  },
  created_at() {
    return faker.date.past();
  },
  updated_at() {
    return faker.date.past();
  },
  "products_price": faker.commerce.price(),
  "service_fee": 0,
  "nu_id": 0
});
