/**
 * @param {String} date  a string that can be parsed as date
 * @param {Boolean} withTime  must concat time with HH:MM format
 * @return {String} date with DD/MM/YYYY format
 */
export default function(date, withTime = true) {
  const d = new Date(date);

  const formatedDate = [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map(n => n < 10 ? `0${n}` : `${n}`).join("/");

  const time = [d.getHours(), d.getMinutes()]
    .map(n => n < 10 ? `0${n}` : `${n}`).join(":");

  return withTime ? `${formatedDate} ${time}` : formatedDate;
}
