import axios from "axios";
import Api from "./api";

class FridgeService extends Api {
  lockProjection(id) {
    return axios({ url: `${this.apiVersion}/admin/fridge_views/${id}/lock`, method: "PUT" });
  }

  unlockProjection(id) {
    return axios({ url: `${this.apiVersion}/admin/fridge_views/${id}/unlock`, method: "PUT" });
  }

  updateSlot(id, data) {
    return axios({ url: `${this.apiVersion}/admin/fridge_view_slots/${id}`, data, method: "PUT" });
  }

  syncFridge(id) {
    return axios({ url: `${this.apiVersion}/admin/fridges/${id}/sync`, method: "POST" });
  }

  exportProjection(id, format = "pdf", text = "") {
    return axios({
      url: `${this.apiVersion}/admin/fridge_views/${id}/${format}${text ? "?text=" + encodeURIComponent(text) : ""}`,
      method: "GET",
      responseType: "blob"
    });
  }

  exportOutput(id) {
    return axios({
      url: `${this.apiVersion}/admin/exports/order_products.csv`,
      method: "GET",
      responseType: "blob",
      params: {
        fridge_id: id
      }
    });
  }

  updatePrice(id, data) {
    return axios({ url: `${this.apiVersion}/admin/slots/${id}`, data, method: "PUT" });
  }
}

export default new FridgeService({
  ressource: "/admin/fridges"
});
