import isObject from "./isObject";

function formatError(errors) {
  console.log("formatError", typeof errors);

  if (isObject(errors) && Object.keys(errors).length > 0) {
    const makeErrors = errors => Object.keys(errors)
      .map(key => errors[key].map(detail => `<li><b>${key}</b> ${detail}</li>`).join(""));

    return `<ul>${makeErrors(errors).join("")}</ul>`;
  }

  return errors;
}

function formatDeviseError(errors) {
  if (!Array.isArray(errors)) {
    return "Service indisponible";
  }

  const makeErrors = errors.map(error => `<li>${error}</li>`).join("");

  return `<ul>${makeErrors}</ul>`;
}

export {
  formatDeviseError,
  formatError
};
