import { createApp } from "vue";
import Axios from "axios";
import * as relativeTime from "dayjs/plugin/relativeTime";
import * as fr from "dayjs/locale/fr";
import dayjs from "dayjs";
import installElementPlus from "./plugins/element";

import App from "./App";
import router from "./router";

import startMirage from "./mirage/server";
import startSentry from "./monitoring/sentry";
import "./assets/style.scss"; // eslint-disable-line import/no-unassigned-import
import store from "./store";

dayjs.locale(fr);
dayjs.extend(relativeTime);

const isProduction = process.env.NODE_ENV === "production"; // eslint-disable-line no-undef
const isDev = process.env.NODE_ENV === "development"; // eslint-disable-line no-undef
const app = createApp(App);

if (isProduction) {
  startSentry(app, router);
}

app.use(store).use(router);

if (isDev && false) { // eslint-disable-line no-constant-condition
  startMirage({
    apiHost: process.env.VUE_APP_API_URL,
    apiVersion: process.env.VUE_APP_API_VERSION,
    bypass: []
  }); // bypass ["auth", "product"]
}

installElementPlus(app);

Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
app.config.globalProperties.$http = Axios;

const token = localStorage.getItem("token");

if (token) {
  app.config.globalProperties.$http.defaults.headers.common["Authorization"] = `Token token=${token}`;
}

app.mount("#app");
