<template>
  <input
    class="InputGroup__field"
    :value="modelValue"
    :placeholder="placeholder"
    :required="required"
    :readonly="readonly"
    @input="updateValue($event.target.value)"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue: function(value) {
      this.$emit("update:modelValue", value);
    }
  }
};
</script>
