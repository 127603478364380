import axios from "axios";
import Api from "./api";

class ProductService extends Api {
  getNuInfos(id) {
    return axios({ url: `${this.apiVersion}${this.ressource}/${id}/sync`, method: "POST" });
  }
  getNuInfosByBatch(id) {
    return axios({ url: `${this.apiVersion}${this.ressource}/sync`, method: "POST" });
  }
  getAllLight() {
    return axios({ url: `${this.apiVersion}${this.ressource}/light`, method: "GET" });
  }
}

export default new ProductService({
  ressource: "/admin/products"
});
