<template>
  <div class="PageTitle">
    <div class="PageTitle__name">Passage de commande pour le stock {{ stock.name }}</div>
  </div>
  <div class="PageTitle__row">
    <router-link :to="{ name: 'StockTourSelect', params: { id: stock.id }, query: $route.query }"
      class="PageTitle__link"
    >
      <el-icon class="PageTitle__icon">
        <el-icon-back />
      </el-icon>
      Retourner à la sélection des tournées</router-link>

    <div class="PageTitle__subheading">
      Tournées sélectionnées :
    </div>
    <div class="fridge-list">
      <div class="fridge-list-item" v-for="p in projections" :key="p.id"><span class="date">{{ dateFormat(p.date, false) }}</span> {{ p.fridge.name }}</div>
    </div>

    <div class="PageTitle__subheading">
      Voici le stock nécessaire pour remplir les différentes tournées que vous avez
      provisionnées :
    </div>
  </div>

  <div class="OrderLayout">
    <el-table :data="products" style="width: 100%;">
      <el-table-column prop="name" label="Nom du produit" width="auto" />
      <el-table-column
        prop="preventive_quantity"
        label="Seuil minimum"
        width="120"
        align="center"
      />
      <el-table-column
        prop="quantity"
        label="Qté réelle"
        width="120"
        align="center"
      />
      <el-table-column
        prop="ordered_quantity"
        label="Qté en cours de commande"
        width="120"
        align="center"
      />
      <el-table-column
        prop="required_quantity"
        label="Qté requises"
        width="120"
        align="center"
      />
      <el-table-column label="Qté PCB" width="120" align="center">
        <template #default="scope">
          <template v-if="scope.row.pcb"> {{ scope.row.pcb }}</template>
          <input
            v-else
            type="text"
            class="OrderLayout__input"
            :class="!scope.row.pcb && scope.row.required_quantity > 0 ? 'OrderLayout__input--required' : ''"
            @blur="(e) => savePcb(scope.row, e.target.value)"
          />
        </template>
      </el-table-column>

      <el-table-column label="PCB à commander" width="120" align="center">
        <template #default="scope">
          <div class="buttons-block">
            <button
              :disabled="!scope.row.pcb"
              class="buttons"
              @click="decrement(scope.row)"
            >
              -
            </button>
            <input
              :disabled="!scope.row.pcb"
              type="text"
              class="OrderLayout__input"
              :value="scope.row.pcb_to_order"
            />
            <button
              :disabled="!scope.row.pcb"
              class="buttons"
              @click="increment(scope.row)"
            >
              +
            </button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Nbr pc." width="120" align="center">
        <template #default="scope">
          {{ getPcByProduct(scope.row) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="OrderLayout__footer">
      <el-button
        type="info"
        @click="showEditOrderDialog = true"
      >Ajouter un produit</el-button>
      <el-button
        type="primary"
        @click="postOrder"
        :disabled="!canOrder"
        :loading="isOrderLoading"
      >Passer commande</el-button>
      <el-dialog
        title="Ajouter un produit à la commande"
        width="450px"
        v-model="showEditOrderDialog"
        destroyOnClose
      >
        <el-form labelPosition="top">
          <el-form-item label="Sélectionner un produit">
            <el-select v-model="selectedProductId" filterable placeholder="choisir un produit" style="width:100%">
              <el-option
                class="OrderLayout__option"
                :key="product.id"
                :label="product.name"
                :value="product.id"
                v-for="product in addableProducts"
              />
            </el-select>
          </el-form-item>

          <div class="align-right">
            <el-button
              @click="showEditOrderDialog = false"
              type="info"
            >Annuler</el-button>
            <el-button
              type="primary"
              :disabled="isLoadingOrder"
              @click="addProduct"
            >Ajouter</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import StockService from "../services/stock";
import notificationService from "../services/notification";
import productService from "../services/product";
import dateFormat from "../utils/dateFormat";

export default {
  beforeRouteEnter(to, from, next) {
    if (!to.query.projection_ids) {
      next({
        name: "StockTourSelect",
        params: { id: to.params.id }
      });
    } else {
      next();
    }
  },
  data() {
    return {
      order: {},
      stock: {},
      products: [],
      addableProducts: [],
      isOrderLoading: false,
      showEditOrderDialog: false,
      selectedProductId: null,
      projections: []
    };
  },
  computed: {
    canOrder() {
      return (
        this.products.every(product => product.pcb)
        && this.products.some(product => product.pcb_to_order > 0)
      );
    }
  },
  methods: {
    getPendingOrder(projection_ids) {
      StockService.getPendingOrder(this.$route.params.id, { projection_ids })
        .then(({ data: order }) => {
          this.order = order;

          this.products = order.products.map(p => ({
            ...p,
            pcb_to_order: p.required_quantity && p.pcb
              ? Math.ceil(p.required_quantity / p.pcb)
              : 0
          }));
        })
        .catch(err => notificationService.error(err.message, err.errors));
    },
    postOrder() {
      this.isOrderLoading = true;

      StockService.postOrder(
        this.$route.params.id,
        {
          projection_ids: this.projections.map(p => p.id),
          products: this.products
            .map(({ id, pcb_to_order }) => ({
              id,
              pcb_to_order
            }))
            .filter(p => p.pcb_to_order > 0)
        },

        { "Content-Type": "application/json" }
      )
        .then(() => notificationService.success("La commande a été passée"))
        .catch(err => notificationService.error(err.message, err.errors))
        .then(() => this.isOrderLoading = false)
        .then(() => this.$router.push({ name: "StockOrders" }));
    },
    getPcByProduct(p) {
      return p.pcb * p.pcb_to_order;
    },
    increment(p) {
      p.pcb_to_order++;
    },
    decrement(p) {
      p.pcb_to_order--;
    },
    savePcb(p, pcb) {
      if (!pcb) {
        return;
      }

      this.isLoading = true;

      const data = new FormData();

      data.append("pcb", pcb);

      productService
        .put(p.id, data)
        .then(() => {
          notificationService.success("les PCB ont été mis à jour");
          p.pcb = pcb;
          p.pcb_to_order = p.required_quantity ? Math.ceil(p.required_quantity / p.pcb) : 0;
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getStock() {
      this.isLoading = true;

      StockService.get(this.$route.params.id)
        .then(({ data: stock }) => {
          this.stock = stock;
          this.addableProducts = stock.products;
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    addProduct() {
      const product = this.addableProducts.find(p => p.id === this.selectedProductId);

      this.products.push({
        ...product,
        id: product.product_id,
        pcb_to_order: 0,
        required_quantity: product.preventive_quantity
      });

      this.showEditOrderDialog = false;
    },
    dateFormat
  },
  created() {
    const projection_ids = JSON.parse(this.$route.query.projection_ids);

    this.getPendingOrder(projection_ids);
    this.getStock();

    StockService.getTours(this.$route.params.id, { future: true })
      .then(({ data: tours }) => this.projections = tours.flatMap(t => t.waiting_order_projections).filter(p => projection_ids.includes(p.id)))
      .catch(err => notificationService.error(err.message, err.errors));
  }
};
</script>

<style lang="scss" scoped>
.PageTitle {
  &__btn {
    color: $color-grey;
    text-decoration: underline;
  }

  &__row {
    margin-top: $global-margin * 2;
  }

  &__link {
    display: flex;
    color: $color-grey;
  }

  &__icon {
    margin-right: $global-margin;
  }

  &__subheading {
    margin-top: $global-margin * 2;
    font-weight: 500;
  }
}

.fridge-list {
  margin-top: $global-margin;
  padding-left: 10px;
  margin-bottom: 40px;
}

.fridge-list-item {
  margin-bottom: $global-margin / 2;
  font-size: 0.8rem;

  .date {
    margin-right: 20px;
  }
}

.OrderLayout {
  margin-top: $global-margin * 2;

  &::v-deep .cell {
    word-break: break-word;
    line-height: 1.2;
  }

  &__input {
    width: 40px;
    height: 24px;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;

    &--required {
      border: 1px solid  $color-label-orange;
    }
  }

  &__footer {
    margin-top: $global-margin * 2;
    float: right;
  }

  &__total {
    margin-right: $global-margin;
    display: inline;
    font-weight: 800;
    font-size: 0.875em;
    color: $color-grey;
  }

  &__totalCount {
    display: inline;
    font-size: 1.3em;
    font-weight: 500;
  }

  &__btn {
    margin-top: $global-margin;
  }

  &__option {
    color: $color-black;
  }
}

.buttons-block {
  display: flex;
  justify-content: space-evenly;
}

.buttons {
  background: $color-grey;
  color: #fff;
  border: none;
  font-size: 12px;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}
</style>
