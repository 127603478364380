import { Factory } from "miragejs";
import faker from "faker";

export default Factory.extend({
  authentication_token() {
    return faker.random.uuid();
  },
  email() {
    return faker.internet.email();
  },
  nu_id() {
    return faker.random.uuid();
  },
  "role": "user",
  created_at() {
    return faker.date.past();
  },
  state: "confirmed"
});
