import axios from "axios";
import Api from "./api";

class StockService extends Api {
  createTour(id, data) {
    return axios({ url: `${this.apiVersion}${this.ressource}/${id}/tours`, data, method: "POST" });
  }
  getTours(id, params = {}) {
    return axios({ url: `${this.apiVersion}${this.ressource}/${id}/tours`,
      method: "GET",
      params });
  }
  getOrders(id, params = {}) {
    return axios({ url: `${this.apiVersion}${this.ressource}/${id}/orders`,
      method: "GET",
      params });
  }
  getPendingOrder(id, params = {}) {
    return axios({ url: `${this.apiVersion}${this.ressource}/${id}/order`, params });
  }

  getOrder(id) {
    return axios({ url: `${this.apiVersion}/admin/buffer_stock_orders/${id}` });
  }
  saveOrderLine(id, fixed_pcb, received_pcb) {
    return axios({
      url: `${this.apiVersion}/admin/buffer_stock_order_lines/${id}`,
      method: "PUT",
      data: { fixed_pcb, received_pcb }
    });
  }

  postOrder(id, data) {
    return axios({ url: `${this.apiVersion}${this.ressource}/${id}/order`, data, method: "POST" });
  }
}

export default new StockService({
  ressource: "/admin/buffer_stocks"
});
