<template>
  <section class="Section EmailConfirmation">
    <div class="Brand">
      <img
        class="Brand__illustration"
        src="../assets/images/logo.png"
        alt="Marie logo"
      />
      <span>FrigoCo</span>
    </div>
    <div class="message" v-if="!isLoading">
      <div v-if="isValid">Votre email a été validé.<br /><br />Vous pouvez à présent vous connecter sur l'application <b>Marie FrigoCo</b></div>
      <div v-else>Ce lien de validation a expiré ou a déjà été utilisé.</div>
    </div>
  </section>
</template>

<script>
import userService from "../services/user";
import notificationService from "../services/notification";

export default {
  data() {
    return {
      message: "",
      isLoading: true,
      isValid: false
    };
  },
  created() {
    return userService.confirmEmail(this.$route.params.token)
      .then(() => this.isValid = true)
      .catch(err => notificationService.error(err.message, err.errors))
      .finally(() => this.isLoading = false);
  }
};
</script>

<style lang="scss" scoped>
.EmailConfirmation {
  min-height: 98vh;
  align-content: center;
}

.Brand {
  margin: 3.75rem auto 2.5rem;
  min-height: 6rem;

  span {
    font-weight: bold;
  }
}

.message {
  text-align: center;
  padding-bottom: 30px;
  line-height: 1.3;
  margin: 0 auto;
  width: 300px;
  max-width: 100%;
  border-bottom: 3px solid $color-primary;
}

</style>
