<template>
  <div>
    <el-form labelPosition="top">
      <div class="Form__title">Créer une nouvelle tournée</div>
      <el-form-item label="Date de livraison/projection">
        <el-date-picker
          v-model="date"
          type="date"
          format="DD-MM-YYYY"
          valueFormat="YYYY-MM-DD"
          placeholder="Choississez un jour"
          :disabledDate="disabledDate"
        />
      </el-form-item>
      <div class="align-right">
        <el-button type="info" :disabled="disabled" @click="onCancel">Annuler</el-button>
        <el-button type="primary" :disabled="disabled" @click="onSubmit">Continuer</el-button>
      </div>
    </el-form>

  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  emits: ["submit", "cancel"],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: ""
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.date);
      this.date = "";
    },
    onCancel() {
      this.date = "";
      this.$emit("cancel");
    },
    disabledDate(date) {
      return dayjs().isAfter(dayjs(date));
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
