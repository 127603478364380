<template>
  <aside class="Menu">
    <router-link :to="{name : 'Orders'}" class="Menu__brand">
      <img
        class="Brand__illustration"
        src="../assets/images/logo.png"
        alt="Marie logo"
      />
      <div class="Brand__baseline">FrigoConnecté</div>
    </router-link>
    <ul>
      <li class="Menu__item"><router-link :to="{name:'Orders'}">Commandes Clients</router-link></li>
      <li class="Menu__separator"></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'products'}" :to="{name:'Products'}">Produits</router-link></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'categories'}" :to="{name:'Categories'}">Catégories</router-link></li>
      <li class="Menu__separator"></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'stocks'}" :to="{name:'Stocks'}">Stocks</router-link></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'fridges'}" :to="{name:'Fridges'}">Frigos</router-link></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'tours'}" :to="{name:'Tours'}">Tournées</router-link></li>
      <li class="Menu__separator"></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'users'}" :to="{name:'Users'}">Utilisateurs</router-link></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'compagnies'}" :to="{name:'Compagnies'}">Compagnies</router-link></li>
      <li class="Menu__separator"></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'faq'}" :to="{name:'Faq'}">FAQ</router-link></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'complaints'}" :to="{name:'Complaints'}">Réclamations</router-link></li>
      <li class="Menu__separator"></li>
      <li class="Menu__item"><router-link :class="{'router-link-active': pathRoot === 'configuration'}" :to="{name:'Configuration'}">Configuration</router-link></li>
      <li class="Menu__item"><a href="#" @click="logout">Déconnexion</a></li>
    </ul>
  </aside>
</template>

<script>
import userService from "../services/user";

export default {
  computed: {
    pathRoot() {
      return this.$route.fullPath.split("/")[1];
    }
  },
  methods: {
    logout: function() {
      userService.logout()
        .finally(() => {
          this.$store.dispatch("auth/logout")
            .then(() => {
              this.$router.push("/login");
            });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.Menu {
  padding: 30px 0 30px;
  background-color: $color-secondary;
  flex: 0 0 220px;

  &__brand {
    text-align: center;
    padding: 30px;
    margin-bottom: $global-margin * 2;
  }

  &__separator {
    margin: 15px 0;
    height: 1px;
    margin-left: 20px;
    margin-right: 30px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &__item {
    text-align: right;
    margin-bottom: 10px;
  }

  ul {
    margin-top: 40px;

    li {
      a {
        display: block;
        padding: 10px 30px;
        font-weight: 500;
        transition: all 0.1s ease;

        &:hover {
          color: #000;
          background: rgba($color-primary, 0.02);
        }

        &.router-link-active {
          border-right: 5px solid $color-primary;
        }
      }
    }
  }
}
</style>
