<template>
  <div class="PageTitle">
    <span class="PageTitle__name">Commandes Clients</span>
    <span v-if="totalCount > 0" class="PageTitle__count">{{ totalCount }}</span>
  </div>
  <div class="Filter">
    <span class="Filter__title">
      Filtrer par
    </span>
    <el-form :inline="true" :model="formFilter">
      <el-form-item label="Frigo" class="Filter__group">
        <el-select v-model="formFilter.fridgeId" clearable placeholder="Tous les frigos">
          <el-option v-for="fridge in fridges" :key="fridge.id" :label="fridge.name" :value="fridge.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="Date de création" class="Filter__group">
        <div class="Filter__date-picker">
          <el-date-picker
            v-model="formFilter.date"
            format="DD/MM/YYYY"
            valueFormat="YYYY-MM-DD"
            type="daterange"
            startPlaceholder="Début"
            endPlaceholder="Fin"
          />
        </div>
      </el-form-item>
    </el-form>
  </div>
  <section class="LineList">
    <div class="LineList__headers">
      <div class="LineList__header">ID</div>
      <div class="LineList__header">Date / heure</div>
      <div class="LineList__header">Produits</div>
      <div class="LineList__header">Total produits</div>
      <div class="LineList__header">Utilisateur</div>
      <div class="LineList__header">Status</div>
      <div class="LineList__header price">Bon d'achat</div>
      <div class="LineList__header price">Montant débité</div>
    </div>
    <Loader v-if="isLoading" />
    <div v-else class="Line" v-for="order in orders" :key="order.id">
      <div class="user-id">#{{ order.id }}</div>
      <div class="date">{{ dateFormat(order.purchase_date) }}</div>
      <div class="products">
        <div v-for="line in order.products" :key="line.product.id">
          {{ line.product.name }} x {{ line.quantity }}
        </div>
      </div>
      <div class="quantity">x{{ getQuantity(order) }}</div>
      <div class="user">{{ order.user_name }}</div>
      <div class="status">{{ order.status.name }}</div>
      <div class="voucher">{{ order.sponsored_amount !== "0.0" ? "-" + priceFormat(order.sponsored_amount) + "€" : "" }}</div>
      <div class="price">{{ priceFormat(order.total_amount) }}€</div>
    </div>
    <div class="pagination" v-if="totalCount > perPage">
      <el-pagination
        :hideOnSinglePage="true"
        :total="totalCount"
        :pageSize="perPage"
        :currentPage="page"
        @current-change="changePage"
        layout="prev, pager, next"
      />
    </div>
  </section>
</template>

<script>
import orderService from "../services/order";
import fridgeService from "../services/fridge";
import dateFormat from "../utils/dateFormat";
import priceFormat from "../utils/priceFormat";
import Loader from "../components/Loader";
import notificationService from "../services/notification";

export default {
  data() {
    return {
      isLoading: false,
      orders: [],
      page: 1,
      perPage: 20,
      totalCount: 0,
      formFilter: {
        fridgeId: "",
        date: []
      },
      fridges: []
    };
  },
  computed: {
    filterQueries() {
      const formatedParams = {
        fridge_id: this.formFilter.fridgeId,
        from: this.formFilter.date ? this.formFilter.date[0] : "",
        to: this.formFilter.date ? this.formFilter.date[1] : ""
      };

      // Only return changed filter params in a single object
      return Object.entries(formatedParams).reduce((acc, [key, value]) => {
        const hasNoValue = !value || value.length === 0;

        if (!hasNoValue) {
          acc[key] = value;
        }

        return acc;
      }, {});
    }
  },
  watch: {
    "$route.query": function() {
      this.isLoading = true;
      this.getOrders();
    },
    formFilter: {
      handler() {
        this.isLoading = true;
        this.changePage(1);
      },
      deep: true
    }
  },
  methods: {
    dateFormat,
    priceFormat,
    getOrders() {
      orderService.getAll({ page: this.page, ...this.filterQueries })
        .then(({ data: orders, headers }) => {
          this.orders = orders;
          this.perPage = parseInt(headers["page-items"]);
          this.totalCount = parseInt(headers["total-count"]);
        })
        .catch(err => notificationService.error(err.message, err.errors))
        .finally(() => this.isLoading = false);
    },
    getFridges() {
      fridgeService.getAll()
        .then(({ data }) => {
          this.fridges = data;
        })
        .catch(err => notificationService.error(err.message, err.errors));
    },

    getQuantity(order) {
      return order.products.reduce((quantity, product) => quantity + product.quantity, 0);
    },

    changePage(p) {
      this.page = p;
      this.$router.push({ to: "Orders", query: { page: this.page, ...this.filterQueries } });
    },
    getStateFromUrlQueries() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;

      // Add formatedParams key names in the array.
      const filterKeys = ["fridgeId", "from", "to"];

      this.formFilter = Object.entries(this.$route.query).reduce((acc, [key, value]) => {
        if (filterKeys.includes(key)) {
          acc[key] = value;
        }

        return acc;
      }, {});
    }
  },
  created() {
    this.isLoading = true;
    this.getStateFromUrlQueries();
    this.getOrders();
    this.getFridges();
  },
  components: {
    Loader
  }
};
</script>
<style lang="scss" scoped>
.LineList__headers,
.Line {
  grid-template-columns: 0.8fr 1fr 3fr 1fr 1fr 1fr 1fr 1fr;
}

.user-id {
  font-weight: bold;
}

.date {
  font-size: 14px;
}

.quantity {
  text-align: center;
}

.price {
  font-weight: bold;
}

.price,
.voucher {
  text-align: right;
}

.products {
  font-size: 12px;
  line-height: 1.4;
}

.pagination {
  text-align: center;
  margin-top: 20px;
}

.Filter {
  padding: 0.5em;
  display: flex;
  align-items: baseline;
  background-color: lighten($color-grey, 50%);

  &__title {
    width: 70px;
    margin-right: 1em;
    font-size: 14px;
    font-weight: 800;
  }

  &__group {
    color: $color-black;
    margin-bottom: 0;
    width: 25%;
  }

  &__date-picker {
    background-color: white;
  }
}
</style>
