<template>
  <el-table :data="projections" style="width: 100%;">
    <el-table-column label="Date" width="110px">
      <template #default="scope">
        <span class="date">{{ dayjs(scope.row.date).format("DD/MM/YYYY") }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Echéance" width="auto">
      <template #default="scope">
        {{ dayjs(scope.row.date).fromNow() }}
      </template>
    </el-table-column>
    <el-table-column width="40px">
      <template #default="scope">
        <el-icon v-if="scope.row.locked">
          <el-icon-lock />
        </el-icon>
      </template>
    </el-table-column>
    <el-table-column width="50px">
      <template #default="scope">
        <span v-if="dayjs().isAfter(dayjs(scope.row.date))" class="export-icon" @click="exportProjection(scope.row, 'csv')">
          CSV
        </span>
      </template>
    </el-table-column>
    <el-table-column width="50px">
      <template #default="scope">
        <span v-if="dayjs().isAfter(dayjs(scope.row.date))" class="export-icon" @click="exportProjection(scope.row, 'pdf')">
          PDF
        </span>
      </template>
    </el-table-column>
    <el-table-column align="right" width="80px">
      <template #default="scope">
        <router-link
          :to="{ name: 'Projections', params: { 'id': fridge.buffer_stock.id}, query: {fridge_id: fridge.id, projection_date: scope.row.date }}"
        >
          Afficher
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import dayjs from "dayjs";
import fridgeService from "../services/fridge";
import notificationService from "../services/notification";

export default {
  props: {
    fridge: {
      type: Object,
      required: true
    },
    projections: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    dayjs,
    exportProjection(projection, extension) {
      fridgeService.exportProjection(projection.id, extension)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", `${projection.date}-${this.fridge.name}.${extension}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => notificationService.error("Erreur lors de l'export de la projection"));
    }
  }
};
</script>

<style lang="scss" scoped>
  .date {
    font-weight: bold;
  }

  .export-icon {
    cursor: pointer;
    font-weight: bold;
    color: $color-grey;
    font-size: 0.8rem;
  }
</style>
