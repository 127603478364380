<template>
  <div class="InputGroup InputGroup--with-icon">
    <input
      :type="showClearPassword ? 'text' : 'password'"
      :value="modelValue"
      class="InputGroup__field"
      :placeholder="placeholder"
      :required="required"
      @input="updateValue($event.target.value)"
      name="password"
      autocomplete="current-password"
    />
    <el-icon
      @click="toggleClearPassword"
      class="InputGroup__icon"
    >
      <el-icon-hide v-if="showClearPassword" />
      <el-icon-view v-else />
    </el-icon>
  </div>
</template>

<script>

export default {
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showClearPassword: false
  }),
  methods: {
    toggleClearPassword: function() {
      this.showClearPassword = !this.showClearPassword;
    },
    updateValue: function(value) {
      this.$emit("update:modelValue", value);
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.InputGroup__icon {
  cursor: pointer;
  right: 1em;
}
</style>
